import http, { publicEndpointUrl } from './http';

export default {
  find: () => http
    .get(publicEndpointUrl('tenants'))
    .then((response) => response.data),
  findById: (id: string) => {
    if (id === 'default') {
      return Promise.resolve({ data: {} });
    }

    return http
      .get(publicEndpointUrl(`tenants/${id}`))
      .then((response) => response.data);
  },
};
