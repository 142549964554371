import React, { useState } from 'react';
import Dropzone from 'react-dropzone';

import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Touchpoint } from '../../../../types';

/* eslint-disable react/jsx-props-no-spreading */
const RVMInput = ({
  disabled,
  rvmUrl,
  formik,
  captureInput,
}: {
  disabled?: boolean,
  rvmUrl: string | undefined,
  formik: {
    values: Touchpoint,
    errors: any,
    touched: any,
    handleBlur: (e: React.FocusEvent<any, Element>) => void,
  },
  captureInput: (target: string, value: any) => void;
}) => {
  const [filename, setFilename] = useState('');
  const [isUpdatingAudio, setIsUpdatingAudio] = useState(false);

  const onRVMFileDrop = async (files: any, name: string) => {
    const [file] = files;

    setFilename(file.name);
    captureInput(name, file);
  };

  const shouldRenderFilename = filename;

  const shouldRenderRVMLink = !filename
    && !isUpdatingAudio
    && !!rvmUrl;

  const shouldRenderRVMDropZone = !filename
    && (isUpdatingAudio || !rvmUrl);

  return (
    <>
      {shouldRenderFilename && (
        <div className="d-flex align-items-center justify-content-center h-100">
          {filename}
        </div>
      )}
      {shouldRenderRVMLink && (
        <div className="d-flex align-items-center justify-content-center h-100">
          <object
            title="Audio Preview"
            data={rvmUrl}
          />
          <FontAwesomeIcon
            className="pointer"
            icon={faPencilAlt as any}
            onClick={() => setIsUpdatingAudio(true)}
          />
        </div>
      )}
      {shouldRenderRVMDropZone && (
        <Dropzone
          disabled={disabled}
          onDrop={(files) => onRVMFileDrop(files, 'newFile')}
          accept=".mp3"
        >
          {({ getRootProps, getInputProps }) => (
            <section className="h-100">
              <div className="h-100" {...getRootProps()}>
                <input {...getInputProps()} />
                <p className="d-flex align-items-center justify-content-center h-100">
                  Drag and drop an MP3 here
                </p>
              </div>
            </section>
          )}
        </Dropzone>
      )}
    </>
  );
};
/* eslint-enable react/jsx-props-no-spreading */

export default RVMInput;
