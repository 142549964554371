import {
  action,
  Action,
  thunk,
  Thunk,
} from 'easy-peasy';
import { StoreModel } from '.';
import { DEFAULT_API_CALL_STATE, DEFAULT_QUERY_LIMIT } from '../../constants';

import api from '../../api';
import { APICallState, Message, ToastType } from '../../types';

enum APICall {
  messageList,
  create,
}

export interface MessageModel {
  // State
  messageList: Message[];
  messageCount: number;
  messageState: APICallState;
  messageListState: APICallState;
  createState: APICallState;
  // Setters
  setMessageList: Action<MessageModel, { list: Message[], count: number }>;
  setAPICallState: Action<MessageModel, { apiCall: APICall, value: APICallState }>;
  // Methods
  getMessages: Thunk<MessageModel, { id: string }>;
  createMessage: Thunk<MessageModel, Message, null, StoreModel>;
}

const message = (): MessageModel => ({
  messageList: [],
  messageCount: 0,
  messageState: { ...DEFAULT_API_CALL_STATE },
  messageListState: { ...DEFAULT_API_CALL_STATE },
  createState: { ...DEFAULT_API_CALL_STATE },
  setMessageList: action((state, data) => {
    state.messageList = data.list;
    state.messageCount = data.count;
  }),
  setAPICallState: action((state, data) => {
    // eslint-disable-next-line default-case
    switch (data.apiCall) {
      case APICall.messageList:
        state.messageListState = data.value;
        break;
      case APICall.create:
        state.createState = data.value;
        break;
    }
  }),
  getMessages: thunk(
    async ({ setAPICallState, setMessageList }, { id }) => {
      setAPICallState({
        apiCall: APICall.messageList,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        const result = await api.messages.find({
          skip: 0,
          limit: DEFAULT_QUERY_LIMIT,
          ordering: 'timestamp',
          filter: { prospectId: id },
        });
        setMessageList({ list: result.response.data, count: result.response.pageCount });
        setAPICallState({
          apiCall: APICall.messageList,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.messageList,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });
      }
    },
  ),
  createMessage: thunk(
    async ({ setAPICallState }, data, helpers) => {
      const { meta: { notify } } = helpers.getStoreActions();

      setAPICallState({
        apiCall: APICall.create,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        await api.messages.create(data);

        setAPICallState({
          apiCall: APICall.create,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });

        notify({
          message: 'Message sent',
          type: ToastType.success,
        });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.create,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });

        notify({
          message: 'Failed to send the message',
          type: ToastType.error,
        });
      }
    },
  ),
});

export default message;
