import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { useStoreActions, useStoreState } from '../../store';

const AddTestLead = ({
  isOpen,
  toggle,
  campaignId,
}: {
  isOpen: boolean,
  toggle: Function,
  campaignId: string,
}) => {
  const profile = useStoreState((state) => state.profile.user);

  const createState = useStoreState((state) => state.lead.createState);
  const createLead = useStoreActions((actions) => actions.lead.createLead);

  const save = () => {
    createLead(campaignId);
    toggle();
  };

  return (
    <Modal
      centered
      backdrop="static"
      isOpen={isOpen}
      style={{ minWidth: '30vw' }}
    >
      <ModalHeader>
        Test Campaign
      </ModalHeader>
      <ModalBody>
        {!profile?.email || !profile?.phoneNumber
          ? 'You must have a email and phone number in order to test the campaign'
          : (
            <>
              <p>Are you sure you want to test this campaign?</p>
              <p>This will create an test lead based on your profile information.</p>
            </>
          )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="second"
          onClick={() => toggle()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!profile?.email || !profile?.phoneNumber || createState.pending}
          onClick={() => save()}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddTestLead;
