import axios from 'axios';

import { ENDPOINT_TYPE } from '../constants';

const endpointUrl = (type: string, url: string) => [process.env.REACT_APP_API_URL, type, url].join('/');

export const publicEndpointUrl = (url: string) => endpointUrl(ENDPOINT_TYPE.PUBLIC, url);
export const authEndpointUrl = (url: string) => endpointUrl(ENDPOINT_TYPE.AUTH, url);

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3001/local',
});

export default http;
