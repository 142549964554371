import {
  action,
  Action,
  thunk,
  Thunk,
} from 'easy-peasy';
import { StoreModel } from '.';
import { DEFAULT_API_CALL_STATE } from '../../constants';

import api from '../../api';
import {
  APICallState,
  Tenant,
  User,
  UserRole,
} from '../../types';

export interface ProfileModel {
  // State
  user: User | null;
  tenant: Tenant | null;
  userState: APICallState;
  tenantState: APICallState;
  updateState: APICallState;
  // Setters
  setUser: Action<ProfileModel, User>;
  setTenant: Action<ProfileModel, Tenant>;
  setUserState: Action<ProfileModel, APICallState>;
  setTenantState: Action<ProfileModel, APICallState>;
  setUpdateState: Action<ProfileModel, APICallState>;
  // Methods
  getProfile: Thunk<ProfileModel, { lean?: boolean }, null, StoreModel>;
  getTenant: Thunk<ProfileModel, string>;
  updateProfile: Thunk<ProfileModel, {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string
  }>;
}

const profile = (): ProfileModel => ({
  user: null,
  tenant: null,
  userState: { ...DEFAULT_API_CALL_STATE },
  tenantState: { ...DEFAULT_API_CALL_STATE },
  updateState: { ...DEFAULT_API_CALL_STATE },
  setUser: action((state, user) => {
    state.user = user;
  }),
  setTenant: action((state, tenant) => {
    state.tenant = tenant;
  }),
  setUserState: action((state, userState) => {
    state.userState = userState;
  }),
  setTenantState: action((state, tenantState) => {
    state.tenantState = tenantState;
  }),
  setUpdateState: action((state, updateState) => {
    state.updateState = updateState;
  }),
  getProfile: thunk(
    async ({
      setUser,
      setUserState,
      getTenant,
      getProfile,
    }, { lean = false }, { dispatch, getStoreState }) => {
      setUserState({ ...DEFAULT_API_CALL_STATE, pending: true });

      try {
        const result = await api.users.profile();
        const user = result.data as User;

        if (user && user._id) {
          setUser(user);
          setUserState({ ...DEFAULT_API_CALL_STATE, success: true });

          // May redirect to the correct tenant after login - wait before starting another process
          if (!lean && process.env.REACT_APP_ENV !== 'development') await getTenant(user._id!);

          dispatch.subscription.getSubscription();
          dispatch.prospect.getPendingResponseCount();

          if (!lean && user.role === UserRole.Owner) {
            dispatch.team.getTeams({ tenantId: user.tenantId });
          } else if (!lean && user.role !== UserRole.Admin) {
            dispatch.team.getTeam({ userId: user._id! });
          }

          dispatch.meta.processUserNotifications(user.metadata!);
        } else {
          const { tenant } = getStoreState().tenant;

          if (tenant) {
            const { linked } = await api.users.patchProfile(tenant?._id!);

            // We have patched the profile that was created through social login
            if (linked) getProfile({ lean });
          }
        }
      } catch (ex) {
        setUserState({ ...DEFAULT_API_CALL_STATE, failure: true });
      }
    },
  ),
  getTenant: thunk(
    async ({ setTenant, setTenantState }, id) => {
      setTenantState({ ...DEFAULT_API_CALL_STATE, pending: true });

      try {
        const result = await api.users.tenant(id);

        const tenant = result as Tenant;
        const location = window.location.host;

        // Redirect to profile tenant if needed
        if (!!tenant && tenant?.domain && tenant.domain !== location) {
          window.location.href = `https://${tenant.domain}`;
        }

        setTenant(tenant);
        setTenantState({ ...DEFAULT_API_CALL_STATE, success: true });
      } catch (ex) {
        setTenantState({ ...DEFAULT_API_CALL_STATE, failure: true });
      }
    },
  ),
  updateProfile: thunk(
    async ({ setUpdateState }, data) => {
      setUpdateState({ ...DEFAULT_API_CALL_STATE, pending: true });

      try {
        await api.users.updateProfile(data as any);
        setUpdateState({ ...DEFAULT_API_CALL_STATE, success: true });
      } catch (ex) {
        setUpdateState({ ...DEFAULT_API_CALL_STATE, failure: true });
      }
    },
  ),
});

export default profile;
