import React, { useState } from 'react';
import { NavLink as RouteNavLink } from 'react-router-dom';
import {
  Label,
  Nav,
  NavbarBrand,
  NavItem,
  NavLink,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserAlt,
  faCog,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import ProspectAlertBadge from '../ProspectAlertBadge';

import { Tenant } from '../../../types';
import { DEFAULT_LOGO_IMAGE } from '../../../constants';

const DesktopNav = ({
  tenant,
  friendlyName,
  isImpersonating,
  signout,
  endImpersonation,
}: {
  tenant: Tenant | null,
  friendlyName: string,
  isImpersonating: boolean,
  signout: Function,
  endImpersonation: Function,
}) => {
  const logo = tenant?.logoUrl || DEFAULT_LOGO_IMAGE;

  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <Nav
      className="justify-content-between align-items-center w-100 d-none d-md-flex"
      tabs
      navbar
    >
      <NavbarBrand
        id="whiteLabel"
        className="d-none d-md-flex align-items-center"
      >
        <img id="whiteLabelImage" alt="Main Logo" src={logo} />
        <h3 className="d-inline logo-text mr-1">GoFetchIt</h3>
        <h3 className="d-inline logo-text-beta font-weight-bold">Beta</h3>
      </NavbarBrand>
      <NavItem className="align-self-end customTab">
        <NavLink
          id="navTabCampaigns"
          className="text-center"
          to="/campaigns"
          activeClassName="active"
          tag={RouteNavLink}
        >
          Campaigns
        </NavLink>
      </NavItem>
      <NavItem className="align-self-end customTab">
        <NavLink
          id="navTabLeads"
          className="text-center"
          to="/leads"
          activeClassName="active"
          tag={RouteNavLink}
        >
          Leads
        </NavLink>
      </NavItem>
      <NavItem
        className="align-self-end customTab"
        style={{ position: 'relative' }}
      >
        <NavLink
          id="navTabProspects"
          className="text-center"
          to="/prospects"
          activeClassName="active"
          tag={RouteNavLink}
        >
          Prospects
        </NavLink>
        <ProspectAlertBadge />
      </NavItem>
      <NavItem
        className="align-self-end customTab"
        style={{ whiteSpace: 'nowrap' }}
      >
        <NavLink
          id="navTabTeam"
          className="text-center"
          to="/team"
          activeClassName="active"
          tag={RouteNavLink}
        >
          My Team
        </NavLink>
      </NavItem>
      <div
        className="d-flex align-items-center justify-content-end"
        style={{ width: '500px', minWidth: '300px' }}
      >
        <div className={`d-flex align-items-center justify-content-center ${isImpersonating ? 'visible' : 'invisible'}`}>
          <FontAwesomeIcon
            id="viewAsEndBtn"
            className="bg-danger text-white rounded mx-1"
            icon={faTimes as any}
            onClick={() => endImpersonation()}
          />
          <Label className="bg-danger text-white m-0 rounded px-1">
            Viewing As:
          </Label>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <FontAwesomeIcon
            className="m-2 justify-content-end text-primary"
            icon={faUserAlt as any}
          />
          <span className="mb-1 userName">{friendlyName}</span>
          <Dropdown
            isOpen={showDropdown}
            toggle={() => setShowDropdown(!showDropdown)}
          >
            <DropdownToggle nav>
              <FontAwesomeIcon
                className="text-secondary"
                size="lg"
                icon={faCog as any}
                id="menuIcon"
              />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <NavLink
                  to="/profile"
                  className="navLinkItem"
                  tag={RouteNavLink}
                >
                  My Profile
                </NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink
                  to="/subscription"
                  className="navLinkItem"
                  tag={RouteNavLink}
                >
                  My Subscription
                </NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink href="#" onClick={() => signout()}>
                  Logout
                </NavLink>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </Nav>
  );
};

export default DesktopNav;
