import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import Paywall from './Paywall';

import { useStoreState } from '../store';
import { NON_AUTHORIZED_REDIRECT_URL } from '../../constants';

const PrivateOutlet = () => {
  const isAuthenticated = useStoreState((state) => state.authentication.isAuthenticated);

  return isAuthenticated
    ? (
      <>
        <Paywall />
        <Outlet />
      </>
    ) : <Navigate to={NON_AUTHORIZED_REDIRECT_URL} />;
};

export default PrivateOutlet;
