import {
  action,
  Action,
  thunk,
  Thunk,
} from 'easy-peasy';
import { StoreModel } from '.';
import { DEFAULT_API_CALL_STATE, DEFAULT_QUERY_LIMIT } from '../../constants';

import api from '../../api';
import { APICallState, Team, ToastType } from '../../types';

enum APICall {
  team,
  teamList,
  create,
  edit,
}

export interface TeamModel {
  // State
  hasTeam: boolean;
  details: Team | null;
  teamList: Team[];
  teamCount: number;
  teamState: APICallState;
  teamListState: APICallState;
  createState: APICallState;
  editState: APICallState;
  // Setters
  setHasTeam: Action<TeamModel, boolean>;
  setTeam: Action<TeamModel, Team>;
  setTeamList: Action<TeamModel, Team[]>;
  setAPICallState: Action<TeamModel, { apiCall: APICall, value: APICallState }>;
  // Methods
  getTeam: Thunk<TeamModel, { userId: string }>;
  getTeams: Thunk<TeamModel, { tenantId: string }>;
  createTeam: Thunk<TeamModel, Team, null, StoreModel>;
  editTeam: Thunk<TeamModel, { _id: string, data: Team }, null, StoreModel>;
}

const team = (): TeamModel => ({
  hasTeam: false,
  details: null,
  teamList: [],
  teamCount: 0,
  teamState: { ...DEFAULT_API_CALL_STATE },
  teamListState: { ...DEFAULT_API_CALL_STATE },
  createState: { ...DEFAULT_API_CALL_STATE },
  editState: { ...DEFAULT_API_CALL_STATE },
  setHasTeam: action((state, hasTeam) => {
    state.hasTeam = hasTeam;
  }),
  setTeam: action((state, team) => {
    state.details = team;
  }),
  setTeamList: action((state, teamList) => {
    state.teamList = teamList;
  }),
  setAPICallState: action((state, data) => {
    // eslint-disable-next-line default-case
    switch (data.apiCall) {
      case APICall.team:
        state.teamState = data.value;
        break;
      case APICall.teamList:
        state.teamListState = data.value;
        break;
      case APICall.create:
        state.createState = data.value;
        break;
      case APICall.edit:
        state.editState = data.value;
        break;
    }
  }),
  getTeam: thunk(
    async ({ setTeam, setHasTeam, setAPICallState }, query) => {
      setAPICallState({
        apiCall: APICall.team,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        const result = await api.teams.findByUserId(query.userId);

        const team = result.response.data;

        setHasTeam(!!team);

        setTeam(team);
        setAPICallState({
          apiCall: APICall.team,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });
      } catch (ex) {
        setHasTeam(false);
        setAPICallState({
          apiCall: APICall.team,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });
      }
    },
  ),
  getTeams: thunk(
    async ({ setTeamList, setHasTeam, setAPICallState }, query) => {
      setAPICallState({
        apiCall: APICall.teamList,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        const result = await api.teams.find({
          skip: 0,
          limit: DEFAULT_QUERY_LIMIT,
          ordering: '-createdAt',
          filter: { ...query },
        });

        const team = result.response.data as Team[];

        setHasTeam(!!team.length);

        setTeamList(team);
        setAPICallState({
          apiCall: APICall.teamList,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });
      } catch (ex) {
        setHasTeam(false);
        setAPICallState({
          apiCall: APICall.teamList,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });
      }
    },
  ),
  createTeam: thunk(
    async ({ setAPICallState, getTeams }, data, helpers) => {
      const { meta: { notify } } = helpers.getStoreActions();

      setAPICallState({
        apiCall: APICall.create,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        await api.teams.create(data);

        setAPICallState({
          apiCall: APICall.create,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });

        notify({
          message: 'Created a new team',
          type: ToastType.success,
        });

        getTeams({ tenantId: data.tenantId });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.create,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });

        notify({
          message: 'Failed to create a new team',
          type: ToastType.error,
        });
      }
    },
  ),
  editTeam: thunk(
    async ({ setAPICallState, getTeams }, { _id, data }, helpers) => {
      const { meta: { notify } } = helpers.getStoreActions();

      setAPICallState({
        apiCall: APICall.edit,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        await api.teams.update(_id, data);

        setAPICallState({
          apiCall: APICall.edit,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });

        notify({
          message: 'Updated the team',
          type: ToastType.success,
        });

        getTeams({ tenantId: data.tenantId });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.edit,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });

        notify({
          message: 'Failed to update the team',
          type: ToastType.error,
        });
      }
    },
  ),
});

export default team;
