import React, { useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Container,
  Col,
  Row,
  Button,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import TopLoader from '../components/TopLoader';
import ListHeader from '../components/ListHeader';
import UserListItem from '../components/UserListItem';

import { UserRole } from '../../types';
import { TEAM_BACKGROUNDS, TOP_LOAD_RATE, TOP_LOAD_START } from '../../constants';

import { useStoreActions, useStoreState } from '../store';

// TODO: Either make a breakdown view of who is in what team
// TODO: Or add a filter by team option
const Users = () => {
  const ref = useRef(null);
  const navigate = useNavigate();

  const {
    user: impersonatedUser,
    isImpersonating,
  } = useStoreState((state) => state.impersonation);

  const profile = useStoreState((state) => state.profile.user);
  const tenantId = useStoreState((state) => state.profile.tenant?._id);

  const userId = isImpersonating ? impersonatedUser?._id : profile?._id;

  const users = useStoreState((state) => state.user.userList);
  const userListState = useStoreState((state) => state.user.userListState);

  const hasTeam = useStoreState((state) => state.team.hasTeam);

  const getUsers = useStoreActions((actions) => actions.user.getUsers);

  const roleQuery = [UserRole.TeamMember, UserRole.TeamLeader];

  if (
    (
      profile?.role === UserRole.Owner
      || profile?.role === UserRole.Admin
    ) && !isImpersonating
  ) roleQuery.push(UserRole.Owner);

  const refreshUsers = () => getUsers({
    userId: userId!,
    tenantId: tenantId!,
    role: { $in: roleQuery },
    includeAvgResponse: true,
  });

  const renderAddTeamButton = () => {
    let addTeamButton = <></>;

    if (
      !isImpersonating
      && profile?.role === UserRole.Owner
    ) {
      addTeamButton = (
        <Button
          onClick={() => navigate('/team/new')}
          outline
          color="secondary"
          className="mx-0 mt-4 pl-0 d-flex align-items-center justify-content-start"
          data-testcafe="team-member-create-button"
        >
          <FontAwesomeIcon icon={faPlusCircle as any} className="mx-4" />
          <span>Add Team</span>
        </Button>
      );
    }

    return addTeamButton;
  };

  const renderAddUserButton = () => {
    let addUserBtn = <></>;

    if (
      !isImpersonating
      && (profile?.role === UserRole.Owner || profile?.role === UserRole.TeamLeader)
    ) {
      addUserBtn = (
        <Button
          disabled={!hasTeam}
          onClick={() => navigate('/team/new/member')}
          outline
          color="secondary"
          className="mx-0 mt-4 pl-0 d-flex align-items-center justify-content-start"
          data-testcafe="team-member-create-button"
        >
          <FontAwesomeIcon icon={faPlusCircle as any} className="mx-4" />
          <span>Add Team Member</span>
        </Button>
      );
    }

    return addUserBtn;
  };

  useEffect(() => {
    if (profile) refreshUsers();
  }, [profile, userId]);

  useEffect(() => {
    if (userListState.pending) {
      (ref.current as any).continuousStart(
        TOP_LOAD_START,
        TOP_LOAD_RATE,
      );
    }

    if (userListState.success || userListState.failure) (ref.current as any).complete();
  }, [userListState]);

  const background = profile?.role === UserRole.Admin || profile?.role === UserRole.Owner
    ? TEAM_BACKGROUNDS.OWNER
    : TEAM_BACKGROUNDS.TEAM_LEADER;
  const link = profile?.role === UserRole.Admin || profile?.role === UserRole.Owner
    ? (
      <p>
        {'No records to display. Get started by '}
        <NavLink to="/team/new">creating a team</NavLink>
        {' or '}
        <NavLink to="/team/new/member">adding a team member</NavLink>
        {' now!'}
      </p>
    )
    : (
      <p>
        {'No records to display. Get started by '}
        <NavLink to="/team/new/member">adding a team member</NavLink>
        {' now!'}
      </p>
    );

  return (
    <Container className="d-flex justify-content-center">
      <TopLoader reference={ref} />
      <Col style={{ width: '90vw' }}>
        <ListHeader
          display={profile?.role !== UserRole.TeamMember
            && profile?.role !== UserRole.Admin
            && userListState.success
            && !users.length}
          background={{
            image: background,
            description: 'Empty team list',
          }}
          link={link}
        />
        <Row>
          {renderAddTeamButton()}
          {renderAddUserButton()}
        </Row>
        {userListState.success
          && users.map((user) => (
            <UserListItem
              key={user._id}
              user={user}
            />
          ))}
      </Col>
    </Container>
  );
};

export default Users;
