import React, { useState } from 'react';
import {
  Row,
  Card,
  CardHeader,
  CardBody,
  Col,
  CardText,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  InputGroup,
  Label,
} from 'reactstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';

import noop from 'lodash.noop';

import { useStoreActions, useStoreState } from '../store';

const UserSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required'),
  lastName: Yup.string()
    .required('Required'),
  email: Yup.string()
    .required('Required')
    .email('Invalid Email'),
  phoneNumber: Yup.string()
    .required('Required')
    .phone('US', 'Invalid Phone Number'),
});

const Profile = () => {
  const [isEditMode, setIsEditMode] = useState(false);

  const profile = useStoreState((state) => state.profile.user);

  const getProfile = useStoreActions((actions) => actions.profile.getProfile);
  const updateProfile = useStoreActions((actions) => actions.profile.updateProfile);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = useFormik({
    validationSchema: UserSchema,
    initialValues: {
      firstName: profile?.firstName ?? '',
      lastName: profile?.lastName ?? '',
      email: profile?.email ?? '',
      phoneNumber: profile?.phoneNumber ?? '',
    },
    onSubmit: noop,
  });

  const [data, setData] = useState({
    firstName: profile?.firstName,
    lastName: profile?.lastName,
    email: profile?.email,
    phoneNumber: profile?.phoneNumber,
  });

  const isUserValid = UserSchema.isValidSync(data);

  const captureInput = (e: any) => {
    const {
      target: { name, value },
    } = e;

    setData({
      ...data,
      [name]: value,
    });
  };

  const onEdit = () => setIsEditMode(true);

  const onSave = async () => {
    if (!isUserValid) return;

    await updateProfile(data as any);
    setIsEditMode(false);
    await getProfile({ lean: true });
  };

  return (
    <div className="my-profile mt-5">
      <Row>
        <Col xs="4" />
        <Col xs="4">
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between">
                <p>Basic Details</p>
                <Button disabled={isEditMode && !isUserValid} onClick={isEditMode ? onSave : onEdit}>{isEditMode ? 'Save' : 'Edit'}</Button>
              </div>
            </CardHeader>
            {/* eslint-disable react/jsx-wrap-multilines */}
            {isEditMode
              ? (
                <CardBody>
                  <Form style={{ width: '100%' }}>
                    <FormGroup row>
                      <Col md="3">
                        <Label htmlFor="text-input">First Name</Label>
                      </Col>
                      <Col xs="12" md="9">
                        <InputGroup>
                          <Input
                            id="text-input"
                            type="text"
                            name="firstName"
                            placeholder="Enter First Name"
                            onChange={(e) => {
                              handleChange(e);
                              captureInput(e);
                            }}
                            onBlur={handleBlur}
                            value={values.firstName}
                            invalid={touched.firstName && !!errors.firstName}
                          />
                          <FormFeedback>{errors.firstName}</FormFeedback>
                        </InputGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md="3">
                        <Label htmlFor="textarea-input">Last Name</Label>
                      </Col>
                      <Col xs="12" md="9">
                        <InputGroup>
                          <Input
                            id="text-input"
                            type="text"
                            name="lastName"
                            placeholder="Enter Last Name"
                            onChange={(e) => {
                              handleChange(e);
                              captureInput(e);
                            }}
                            onBlur={handleBlur}
                            value={values.lastName}
                            invalid={touched.lastName && !!errors.lastName}
                          />
                          <FormFeedback>{errors.lastName}</FormFeedback>
                        </InputGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md="3">
                        <Label htmlFor="email-input">Email</Label>
                      </Col>
                      <Col xs="12" md="9">
                        <InputGroup>
                          <Input
                            id="email-input"
                            type="email"
                            name="email"
                            placeholder="Enter Email"
                            autoComplete="email"
                            onChange={(e) => {
                              handleChange(e);
                              captureInput(e);
                            }}
                            onBlur={handleBlur}
                            value={values.email}
                            invalid={touched.email && !!errors.email}
                          />
                          <FormFeedback>{errors.email}</FormFeedback>
                        </InputGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md="3">
                        <Label htmlFor="email-input">Phone Number</Label>
                      </Col>
                      <Col xs="12" md="9">
                        <InputGroup>
                          <Input
                            id="phoneNumber-input"
                            type="text"
                            name="phoneNumber"
                            placeholder="Enter Phone Number"
                            autoComplete="phoneNumber"
                            onChange={(e) => {
                              handleChange(e);
                              captureInput(e);
                            }}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                            invalid={touched.phoneNumber && !!errors.phoneNumber}
                          />
                          <FormFeedback>{errors.phoneNumber}</FormFeedback>
                        </InputGroup>
                      </Col>
                    </FormGroup>
                    <div className="d-flex">
                      <CardText className="font-weight-bold mr-2">
                        Role:
                      </CardText>
                      <CardText>{profile?.role}</CardText>
                    </div>
                  </Form>
                </CardBody>)
              : (
                <CardBody>
                  <div className="d-flex">
                    <CardText className="font-weight-bold mr-2">
                      First Name:
                    </CardText>
                    <CardText>{profile?.firstName}</CardText>
                  </div>
                  <div className="d-flex">
                    <CardText className="font-weight-bold mr-2">
                      Last Name:
                    </CardText>
                    <CardText>{profile?.lastName}</CardText>
                  </div>
                  <div className="d-flex">
                    <CardText className="font-weight-bold mr-2">
                      Email:
                    </CardText>
                    <CardText>{profile?.email}</CardText>
                  </div>
                  <div className="d-flex">
                    <CardText className="font-weight-bold mr-2">
                      Phone Number:
                    </CardText>
                    <CardText>{profile?.phoneNumber}</CardText>
                  </div>
                  <div className="d-flex">
                    <CardText className="font-weight-bold mr-2">
                      Role:
                    </CardText>
                    <CardText>{profile?.role}</CardText>
                  </div>
                </CardBody>)}
            {/* eslint-enable react/jsx-wrap-multilines */}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
