import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';

import _isEmpty from 'lodash.isempty';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';

import genKey from '../../utils/genKey';

import TopLoader from '../components/TopLoader';
import SetupLeads from '../components/SetupLeads';
import ListHeader from '../components/ListHeader';
import LeadListItem from '../components/LeadListItem';

import { LEAD_BACKGROUND_IMAGE, TOP_LOAD_RATE, TOP_LOAD_START } from '../../constants';

import { useStoreState, useStoreActions } from '../store';

import '../../styles/prospects.scss';

const Leads = () => {
  const ref = useRef(null);

  const profile = useStoreState((state) => state.profile.user);

  const leads = useStoreState((state) => state.lead.leadList);
  const leadListState = useStoreState((state) => state.lead.leadListState);

  const {
    user: impersonatedUser,
    isImpersonating,
  } = useStoreState((state) => state.impersonation);
  const profileId = useStoreState((state) => state.profile.user?._id);

  const userId = isImpersonating ? impersonatedUser?._id : profileId;

  const [order, setOrder] = useState('createdAt');

  const getLeads = useStoreActions((actions) => actions.lead.getLeads);

  const refreshLeads = () => getLeads({ order, userId: userId! });

  const renderSortIcon = (sortValue: string, sortText: string, order = 'createdAt') => {
    const isDescending = order.includes('-');
    const orderValue = order.replace('-', '');

    let icon = faSort;
    let sortOrder = sortValue;

    if (orderValue === sortValue) {
      icon = isDescending ? faSortDown : faSortUp;
      sortOrder = isDescending ? sortValue : `-${sortValue}`;
    }

    return (
      <>
        <Col className="pl-3">
          <Row className="py-3 d-flex align-items-center">
            <Col className="d-flex align-items-center">
              <button
                type="button"
                className="listItemName text-secondary"
                data-testcafe={`sort-${sortValue}`}
                onClick={() => setOrder(sortOrder)}
              >
                {`${sortText} `}
                <FontAwesomeIcon icon={icon as any} />
              </button>
            </Col>
          </Row>
        </Col>
      </>
    );
  };

  useEffect(() => {
    if (userId) refreshLeads();
  }, [order, userId]);

  useEffect(() => {
    if (leadListState.pending) {
      (ref.current as any).continuousStart(
        TOP_LOAD_START,
        TOP_LOAD_RATE,
      );
    }

    if (leadListState.success || leadListState.failure) (ref.current as any).complete();
  }, [leadListState]);

  return (
    <Container className="d-flex justify-content-center">
      <TopLoader reference={ref} />
      <Col style={{ width: '90vw' }}>
        <ListHeader
          display={!profile?.metadata?.setupLeads
            && leadListState.success
            && !leads.length}
          background={{
            image: LEAD_BACKGROUND_IMAGE,
            description: 'Empty Lead List',
          }}
          link={
            !profile?.metadata?.hadLead?.value
              ? (
                <p>
                  {'No records to display. '}
                  <NavLink to="/campaigns/new">Start a campaign</NavLink>
                  {' to see your leads appear here!'}
                </p>
              )
              : (
                <p>
                  No records to display. More leads will appear as they go through your campaigns.
                </p>
              )
          }
        >
          {!profile?.metadata?.hadLead?.value && (
            <p>
              {'Learn more about automating your lead generation with '}
              <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_ZAPIER_APP_INVITE_LINK}>Zapier</a>
              .
            </p>
          )}
        </ListHeader>
        {profile?.metadata?.setupLeads && leadListState.success && !leads.length
          ? <SetupLeads />
          : (
            <>
              <Row className="flex rounded">
                {renderSortIcon('firstName', 'Fullname', order)}
                <Col className="pl-3">
                  <Row className="py-3 d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <p className="listItemName text-secondary">Campaign</p>
                    </div>
                  </Row>
                </Col>
              </Row>
              {leadListState.success && leads.map((lead) => (
                <LeadListItem
                  key={genKey()}
                  lead={lead}
                />
              ))}
            </>
          )}
      </Col>
    </Container>
  );
};

export default Leads;
