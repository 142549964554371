import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faReply,
  faEnvelope,
  faPhoneAlt,
  faSignInAlt,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';

import TopLoader from '../components/TopLoader';
import MessageItem from '../components/Message';
import SendSMSModal from '../components/Modals/SendSMSModal';
import SendEmailModal from '../components/Modals/SendEmailModal';

import { formatDate } from '../../utils/moments';
import usedAllocation from '../../utils/usedAllocation';

import { Message, MessageStatus } from '../../types';
import { TOP_LOAD_RATE, TOP_LOAD_START } from '../../constants';

import { useStoreState, useStoreActions } from '../store';

import '../../styles/prospect.scss';

const Messages = ({
  isProspect = true,
}: { isProspect?: boolean }) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.id;

  const lead = useStoreState((state) => state.lead.lead);
  const prospect = useStoreState((state) => state.prospect.prospect);
  const messages = useStoreState((state) => state.message.messageList);
  const subscription = useStoreState((state) => state.subscription.subscription);

  const leadState = useStoreState((state) => state.lead.leadState);
  const createState = useStoreState((state) => state.message.createState);
  const prospectState = useStoreState((state) => state.prospect.prospectState);
  const messageListState = useStoreState((state) => state.message.messageListState);

  const details = isProspect ? prospect : lead;
  const detailsState = isProspect ? prospectState : leadState;

  const {
    user: impersonatedUser,
    isImpersonating,
  } = useStoreState((state) => state.impersonation);
  const profileId = useStoreState((state) => state.profile.user?._id);

  const userId = isImpersonating ? impersonatedUser?._id : profileId;

  const getLead = useStoreActions((actions) => actions.lead.getLead);
  const getProspect = useStoreActions((actions) => actions.prospect.getProspect);

  const getDetails = isProspect ? getProspect : getLead;

  const getMessages = useStoreActions((actions) => actions.message.getMessages);
  // eslint-disable-next-line max-len
  const getPendingResponseCount = useStoreActions((actions) => actions.prospect.getPendingResponseCount);

  const createMessage = useStoreActions((actions) => actions.message.createMessage);

  const refresh = async () => {
    await getDetails(id!);
    getMessages({ id: id! });
  };

  const getFirstReceivedMessageDate = () => {
    const receivedMessages = messages.filter(
      (message) => message.status === MessageStatus.Received,
    );

    let respondedDate = "Hasn't Responded";

    if (receivedMessages.length) {
      const firstResponse = receivedMessages[0];

      respondedDate = formatDate(firstResponse.timestamp);
    }

    return respondedDate;
  };

  const sendMessage = async (data: Message) => {
    await createMessage({
      ...data,
      prospectId: id!,
      status: MessageStatus.Outgoing,
    });

    if (isProspect) await getPendingResponseCount();

    await refresh();
  };

  useEffect(() => { refresh(); }, [userId]);

  useEffect(() => {
    if (detailsState.pending) {
      (ref.current as any).continuousStart(
        TOP_LOAD_START,
        TOP_LOAD_RATE,
      );
    }

    if (messageListState.success || messageListState.failure) (ref.current as any).complete();
  }, [detailsState, messageListState]);

  useEffect(() => {
    if (createState.pending) {
      (ref.current as any).continuousStart(
        TOP_LOAD_START,
        TOP_LOAD_RATE,
      );
    }

    if (createState.success || createState.failure) (ref.current as any).complete();
  }, [createState]);

  return (
    <div id="Prospect" className="prospectPage">
      <TopLoader reference={ref} />
      <div className="header d-flex">
        {detailsState.success && (
          <>
            <div className="d-flex">
              <FontAwesomeIcon
                className="prospectHeaderIcon"
                icon={faUser as any}
                size="3x"
              />
              <div className="d-flex flex-column prospectHeaderDetails">
                <div>
                  <FontAwesomeIcon icon={faEnvelope as any} size="sm" />
                  &nbsp;
                  {details?.email}
                </div>
                <div>
                  <FontAwesomeIcon icon={faPhoneAlt as any} size="sm" />
                  &nbsp;
                  {details?.phoneNumber}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column">
              <div className="mb-2">
                Entered Campaign: &nbsp;
                <span className="date">
                  <FontAwesomeIcon icon={faSignInAlt as any} />
                  &nbsp;
                  {formatDate(details?.createdAt as Date)}
                </span>
              </div>
              {messageListState.success && (
                <div>
                  Responded to Campaign: &nbsp;
                  <span className="date">
                    <FontAwesomeIcon icon={faSignOutAlt as any} />
                    &nbsp;
                    {getFirstReceivedMessageDate()}
                  </span>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      {messageListState.success && (
        <div className="messages">
          {messages.map((message) => (
            <MessageItem key={message._id} message={message} />
          ))}
        </div>
      )}

      <div className="footer">
        <SendEmailModal
          disabled={isImpersonating
            || createState.pending
            || usedAllocation(subscription?.limits.messages)
            || usedAllocation(subscription?.limits.email)}
          sendMessage={sendMessage}
        />
        <SendSMSModal
          disabled={isImpersonating
            || createState.pending
            || usedAllocation(subscription?.limits.messages)
            || usedAllocation(subscription?.limits.sms)}
          sendMessage={sendMessage}
        />
        <Button
          className="m-2"
          style={{
            background: 'transparent',
            border: 'none',
            color: '#0d6efd',
          }}
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faReply as any} size="lg" />
        </Button>
      </div>
    </div>
  );
};

export default Messages;
