import React, { useState } from 'react';
import {
  Button,
  FormFeedback,
  Input,
  InputGroup,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faCheck } from '@fortawesome/free-solid-svg-icons';

import { Touchpoint } from '../../../types';

const NameInput = ({
  disabled = false,
  formik,
  setValue,
}: {
  disabled?: boolean,
  formik: {
    values: { name: string, touchpoints: Touchpoint[] },
    errors: any,
    touched: any,
    handleBlur: (e: React.FocusEvent<any, Element>) => void,
    handleChange: (e: React.ChangeEvent<any>) => void,
  },
  setValue: Function,
}) => {
  const {
    values: {
      name,
    },
    errors,
    touched,
    handleBlur,
    handleChange,
  } = formik;

  const [showNameText, setShowNameText] = useState(true);

  const toggleEditCampaignName = () => setShowNameText(!showNameText);

  return (
    <>
      {showNameText && (
        <h3 data-testcafe="campaign-name-text">
          {name}
          &nbsp;
          {!disabled && (
            <Button
              color="link"
              onClick={toggleEditCampaignName}
            >
              <FontAwesomeIcon icon={faPencilAlt as any} />
            </Button>
          )}
        </h3>
      )}
      {!showNameText && (
        <div className="d-flex">
          <InputGroup>
            <Input
              style={{ minWidth: '270px' }}
              type="text"
              name="name"
              placeholder="Campaign Name"
              onChange={(e) => {
                handleChange(e);
                setValue(e.target.value);
              }}
              onBlur={handleBlur}
              value={name}
              invalid={touched.name && !!errors.name}
            />
            <FormFeedback>{errors.name}</FormFeedback>
          </InputGroup>
          <Button
            color="link"
            disabled={!name}
            onClick={toggleEditCampaignName}
          >
            <FontAwesomeIcon icon={faCheck as any} />
          </Button>
        </div>
      )}
    </>
  );
};

export default NameInput;
