import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Col,
  Row,
  Alert,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Input,
} from 'reactstrap';

import isEmpty from 'lodash.isempty';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Tenant, User } from '../../types';
import { DEFAULT_LOGO_IMAGE } from '../../constants';

import { useStoreState, useStoreActions } from '../store';

import '../../styles/signup.scss';

const SignUpSchema = () => Yup.object().shape({
  password: Yup.string()
    .min(8, 'A minimum of 8 characters is required')
    .matches(/(?=.*[a-z])/, 'Must include a lowercase character')
    .matches(/(?=.*[A-Z])/, 'Must include a uppercase character')
    .matches(/(?=.*[-+_!@#$%^&*., ?])/, 'Must include a special character')
    .matches(/\d/, 'Must include a number')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .when('password', {
      is: (val: string) => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Both password need to be the same',
      ),
    })
    .required(),
  firstName: Yup.string().required('First Name is required!'),
  lastName: Yup.string().required('Last Name is required!'),
  email: Yup.string()
    .required('Email is required!')
    .email('Invalid email address'),
});

const getErrorsFromValidationError = (validationError: any) => {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors: any, error: any) => ({
    ...errors,
    [error.path]: error.errors[FIRST_ERROR],
  }), {});
};

const validate = (getValidationSchema: any) => (values: any) => {
  const validationSchemaData = getValidationSchema(values);
  try {
    validationSchemaData.validateSync(values, { abortEarly: false });
    return {};
  } catch (error) {
    console.dir(error);

    return getErrorsFromValidationError(error);
  }
};

const SignUp = () => {
  const tenant = useStoreState((state) => state.tenant.tenant);
  const tenantList = useStoreState((state) => state.tenant.tenantList);
  const tenantListState = useStoreState((state) => state.tenant.tenantListState);

  const registerState = useStoreState((state) => state.registration.registerState);

  const [message, setMessage] = useState('');
  const [data, setData] = useState({
    password: '',
    email: '',
    firstName: '',
    lastName: '',
    tenantId: tenant?._id,
  } as User);

  const logo = tenant?.logoUrl || DEFAULT_LOGO_IMAGE;
  const pageStyle = tenant?.backgroundColor
    ? { backgroundColor: tenant?.backgroundColor }
    : {};

  const background = tenant?.backgroundUrl
    ? { backgroundImage: `url(${tenant?.backgroundUrl})` }
    : pageStyle;

  const navigate = useNavigate();
  const register = useStoreActions((actions) => actions.registration.register);

  const setValue = (e: any) => setData({
    ...data,
    [e.target.name]: e.target.value,
  });

  const onSave = () => register(data);

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      onSave();
    }
  };

  const renderTenantDropdown = () => (
    !tenant?._id
    && process.env.REACT_APP_ENV === 'development'
    && tenantListState.success
    && (
      <FormGroup>
        <Input
          id="tenantId"
          name="tenantId"
          placeholder="Tenant"
          value={data.tenantId}
          onChange={setValue}
          type="select"
          data-testcafe="signup-tenant-select"
        >
          <option value="default">-- No Tenant --</option>
          {tenantList.map((t: Tenant) => (
            <option key={t._id} value={t._id}>
              {t.name}
            </option>
          ))}
        </Input>
      </FormGroup>
    )
  );

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    isValid,
  } = useFormik({
    validate: validate(SignUpSchema),
    initialValues: {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      confirmPassword: data.password,
    },
    onSubmit: onSave,
  });

  useEffect(() => {
    if (registerState.failure) setMessage('Failed to create account');
    if (registerState.success) navigate('/entry');
  }, [registerState]);

  return (
    <Col id="signupPage" style={pageStyle}>
      <div id="signupContainer" style={background}>
        <img id="signupLogo" alt="Main Logo" src={logo} />
        <Form id="signupForm" onKeyDown={(e) => handleKeyDown(e)}>
          {registerState.success && (
            <Alert color="success" style={{ width: '100%', zIndex: 100 }}>
              {`User created! Check ${data.email} for further instructions.`}
            </Alert>
          )}
          {registerState.failure && (
            <Alert color="danger" style={{ width: '100%', zIndex: 100 }}>
              {message}
            </Alert>
          )}
          {renderTenantDropdown()}
          <FormGroup>
            <Input
              required
              id="email"
              name="email"
              type="email"
              placeholder="Email"
              data-testcafe="signup-username-email-input"
              onChange={handleChange}
              onInput={setValue}
              onBlur={handleBlur}
              value={values.email}
              valid={!errors.email}
              invalid={touched.email && !!errors.email}
            />
            <FormFeedback>{errors.email}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              required
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              data-testcafe="signup-password-input"
              onChange={handleChange}
              onInput={setValue}
              onBlur={handleBlur}
              value={values.password}
              valid={!errors.password}
              invalid={touched.password && !!errors.password}
            />
            <FormFeedback>{errors.password}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              required
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              data-testcafe="signup-confirm-password-input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
              valid={!errors.confirmPassword}
              invalid={
                touched.confirmPassword && !!errors.confirmPassword
              }
            />
            <FormFeedback>{errors.confirmPassword}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              required
              id="firstName"
              name="firstName"
              type="text"
              placeholder="Enter First Name"
              data-testcafe="signup-firstname-input"
              onChange={handleChange}
              onInput={setValue}
              onBlur={handleBlur}
              value={values.firstName}
              valid={!errors.firstName}
              invalid={touched.firstName && !!errors.firstName}
            />
            <FormFeedback>{errors.firstName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              required
              id="lastName"
              name="lastName"
              type="text"
              placeholder="Enter Last Name"
              data-testcafe="signup-lastname-input"
              onChange={handleChange}
              onInput={setValue}
              onBlur={handleBlur}
              value={values.lastName}
              valid={!errors.lastName}
              invalid={touched.lastName && !!errors.lastName}
            />
            <FormFeedback>{errors.lastName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col className="signupButtonContainer">
                <Button
                  id="signupBtn"
                  disabled={isSubmitting || isEmpty(touched) || !isValid}
                  onClick={onSave}
                  data-testcafe="submit-button"
                >
                  Create Account
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="signupButtonContainer">
                <NavLink id="submit" to="/entry">Already have an account?</NavLink>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </div>
    </Col>
  );
};

export default SignUp;
