import {
  action, Action, thunk, Thunk,
} from 'easy-peasy';
import { StoreModel } from '.';

import api from '../../api';

export interface AuthenticationModel {
  token: string;
  isAuthenticated: boolean;
  setIsAuthenticated: Action<AuthenticationModel, boolean>;
  setToken: Action<AuthenticationModel, string>;
  startAuthenticatedSession: Thunk<AuthenticationModel, {
    isAuthenticated: boolean,
    token: string
  }, null, StoreModel>;
}

const authentication = (): AuthenticationModel => ({
  token: '',
  isAuthenticated: false,
  setIsAuthenticated: action((state, isAuthenticated) => {
    state.isAuthenticated = isAuthenticated;
  }),
  setToken: action((state, token) => {
    state.token = token;
  }),
  startAuthenticatedSession: thunk(
    async ({ setIsAuthenticated, setToken }, { isAuthenticated, token }, { dispatch }) => {
      setToken(token);
      setIsAuthenticated(isAuthenticated);

      if (process.env.REACT_APP_ENV === 'development') console.log('setting...', token);

      await api.auth.setBearerToken(token);

      // Authentication set pull the profile
      dispatch.profile.getProfile({ lean: false });
    },
  ),
});

export default authentication;
