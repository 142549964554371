import {
  action,
  Action,
  thunk,
  Thunk,
} from 'easy-peasy';
import { StoreModel } from '.';
import { DEFAULT_API_CALL_STATE, DEFAULT_QUERY_LIMIT } from '../../constants';

import api from '../../api';
import {
  APICallState,
  Lead,
  ProspectStatus,
  ToastType,
} from '../../types';

enum APICall {
  lead,
  leadList,
  create,
}

export interface LeadModel {
  // State
  lead: Lead | null;
  leadList: Lead[];
  leadCount: number;
  leadState: APICallState;
  leadListState: APICallState;
  createState: APICallState;
  // Setters
  setLead: Action<LeadModel, Lead>;
  setLeadList: Action<LeadModel, { list: Lead[], count: number }>;
  setAPICallState: Action<LeadModel, { apiCall: APICall, value: APICallState }>;
  // Methods
  getLead: Thunk<LeadModel, string>;
  getLeads: Thunk<LeadModel, { userId: string, order: string }>;
  createLead: Thunk<LeadModel, string, null, StoreModel>;
}

const lead = (): LeadModel => ({
  lead: null,
  leadList: [],
  leadCount: 0,
  leadState: { ...DEFAULT_API_CALL_STATE },
  leadListState: { ...DEFAULT_API_CALL_STATE },
  createState: { ...DEFAULT_API_CALL_STATE },
  setLead: action((state, data) => {
    state.lead = data;
  }),
  setLeadList: action((state, data) => {
    state.leadList = data.list;
    state.leadCount = data.count;
  }),
  setAPICallState: action((state, data) => {
    // eslint-disable-next-line default-case
    switch (data.apiCall) {
      case APICall.lead:
        state.leadState = data.value;
        break;
      case APICall.leadList:
        state.leadListState = data.value;
        break;
      case APICall.create:
        state.createState = data.value;
        break;
    }
  }),
  getLead: thunk(
    async ({ setAPICallState, setLead }, id) => {
      setAPICallState({
        apiCall: APICall.lead,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        const result = await api.prospects.findById(id);

        setLead(result.data);
        setAPICallState({
          apiCall: APICall.lead,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.lead,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });
      }
    },
  ),
  getLeads: thunk(
    async ({ setAPICallState, setLeadList }, query) => {
      setAPICallState({
        apiCall: APICall.leadList,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        const result = await api.prospects.find({
          skip: 0,
          limit: DEFAULT_QUERY_LIMIT,
          ordering: query.order,
          filter: {
            userId: query.userId,
            status: ProspectStatus.Lead,
          },
        });
        setLeadList({ list: result.response.data, count: result.response.pageCount });
        setAPICallState({
          apiCall: APICall.leadList,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.leadList,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });
      }
    },
  ),
  createLead: thunk(
    async ({ setAPICallState }, campaignId, helpers) => {
      const { profile: { user } } = helpers.getStoreState();
      const { meta: { notify } } = helpers.getStoreActions();

      setAPICallState({
        apiCall: APICall.create,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        await api.prospects.create({
          campaignId,
          isTest: true,
          status: ProspectStatus.Lead,
          firstName: user?.firstName!,
          lastName: user?.lastName!,
          email: user?.email!,
          phoneNumber: user?.phoneNumber!,
        });

        setAPICallState({
          apiCall: APICall.create,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });

        notify({
          message: 'Created a new lead',
          type: ToastType.success,
        });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.create,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });

        notify({
          message: 'Failed to create a new lead',
          type: ToastType.error,
        });
      }
    },
  ),
});

export default lead;
