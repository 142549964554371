import { TouchpointType } from './types';

import defaultLogoImage from './assets/logo.png';
import defaultBackgroundImage from './assets/main-background.jpeg';
import campaignBackgroundImage from './assets/empty-campaign-background.png';
import leadBackgroundImage from './assets/empty-lead-background.png';
import prospectEmptyBackgroundImage from './assets/empty-prospects-background.png';
import convertLeadsBackgroundImage from './assets/convert-leads-background.png';
import exportProspectsBackgroundImage from './assets/export-prospects-background.png';
import emptyTeamLeaderBackgroundImage from './assets/empty-team-leader-background.png';
import emptyTeamOwnerBackgroundImage from './assets/empty-team-owner-background.png';

export const DEFAULT_LOGO_IMAGE = defaultLogoImage;
export const DEFAULT_BACKGROUND_IMAGE = defaultBackgroundImage;
export const CAMPAIGN_BACKGROUND_IMAGE = campaignBackgroundImage;
export const LEAD_BACKGROUND_IMAGE = leadBackgroundImage;
export const PROSPECT_BACKGROUNDS = {
  EMPTY: prospectEmptyBackgroundImage,
  CONVERT_LEADS: convertLeadsBackgroundImage,
  EXPORT: exportProspectsBackgroundImage,
};
export const TEAM_BACKGROUNDS = {
  TEAM_LEADER: emptyTeamLeaderBackgroundImage,
  OWNER: emptyTeamOwnerBackgroundImage,
};

export const LOGGED_IN_REDIRECT_URL = '/campaigns';
export const LOGGED_OUT_REDIRECT_URL = '/entry';
export const NON_AUTHORIZED_REDIRECT_URL = '/entry';
export const PAYMENTS_REDIRECT = '/payment';

export const ENDPOINT_TYPE = {
  PUBLIC: 'public',
  AUTH: 'auth',
};

export const DEFAULT_API_CALL_STATE = {
  failure: false,
  success: false,
  pending: false,
};

export const DEFAULT_QUERY_LIMIT = 50;
export const MAXIMUM_QUERY_LIMIT = 500;

export const TOUCHPOINT_DEFAULT_MESSAGE = {
  sms:
    'Hi {firstname}, \n\nPlease feel free to respond to this message for that amazing offer. \n\nThank you!',
  email: '<div class="row clearfix"><div class="column full" spellcheck="false"><p>Hi {firstname},</p><p>Please feel free to respond to this message for that amazing offer.</p><p>Thank you!</p></div></div>',
};

export const TOUCHPOINT_OPTIONS = [
  {
    display: 'Text Message',
    value: TouchpointType.SMS,
  },
  {
    display: 'Email',
    value: TouchpointType.Email,
  },
  // TODO: Future Enhancment
  // {
  //   display: 'Fancy Email',
  //   value: TouchpointType.FancyEmail,
  // },
  // {
  //   display: 'Ringless Voicemail',
  //   value: TouchpointType.RVM,
  // },
];

export const MAX_SMS_MESSAGE_LENGTH = 160;

export const MAX_INTERVAL_VALUE = 99;
export const MIN_MINUTES_INTERVAL_VALUE = 5;

export const EXPAND_AT = 263;
export const ELLIPSIZE_AT = 260;

export const MOMENT_FORMATS = {
  MONTH_DAY: 'MMM D',
  MONTH_DAY_TIME_PERIOD: 'MMM Do, h:mm A',
  TIME_ELAPSED: 'y [years] M [months] w [weeks] d[d] h[hrs] m [mins] s [s]',
};

export const DEFAULT_POLLING_TIME = 5000;
export const DEFAULT_CALL_RECEIVED_MSG = 'Call received';

export const TOP_LOAD_RATE = 1000;
export const TOP_LOAD_START = 28;
