import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment,
  faEnvelope,
  faVoicemail,
  faExpand,
} from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import {
  EXPAND_AT,
  ELLIPSIZE_AT,
} from '../../constants';

import {
  Message,
  MessageStatus,
  MessageType,
  ProspectStatus,
  UserRole,
} from '../../types';

import formatDate from '../../utils/moments';

import '../../styles/prospect.scss';

const getBody = (message: Message, perspective: string) => {
  const {
    type,
    messageType,
    body: rawBody,
    rvmUrl,
  } = message;

  let body: any = '';
  let isRvm: boolean = false;

  if ((type ?? messageType) === MessageType.RVM) {
    isRvm = !!rvmUrl;
    body = rvmUrl
      ? (
        <a href={rvmUrl} target="_blank" rel="noopener noreferrer" style={{ color: perspective === ProspectStatus.Prospect ? '#38AFFC' : '#fff' }}>
          Voicemail
        </a>
      ) : rawBody!;
  } else {
    const ellipsizedBody = rawBody && rawBody.length > EXPAND_AT
      ? `${rawBody.substring(0, ELLIPSIZE_AT)} …`
      : rawBody;

    body = ellipsizedBody!;
  }

  return { body, isRvm };
};

const getIcon = (type: MessageType) => {
  switch (type) {
    case MessageType.SMS:
      return faComment;
    case MessageType.RVM:
      return faVoicemail;
    case MessageType.Email:
    default:
      return faEnvelope;
  }
};

const MessageItem = ({
  message,
}: {
  message: Message
}) => {
  const {
    timestamp,
    type,
    status,
    // Old field names
    messageType,
    messageStatus,
  } = message;

  const [isOpen, setIsOpen] = useState(false);

  const { Prospect } = ProspectStatus;
  const TeamMember = UserRole.TeamMember.replace(' ', '');

  const perspective = (status ?? messageStatus!) === MessageStatus.Received ? Prospect : TeamMember;
  const displayDate = formatDate(timestamp);

  const internalToggle = () => setIsOpen(!isOpen);

  const { body, isRvm } = getBody(message, perspective);

  return (
    <>
      <div
        className={`col-sm-12 col-md-7 col-lg-6  msgContainer${perspective}`}
      >
        <div className={`date${perspective}`}>{displayDate}</div>
        <div className={`message ${perspective}`}>
          {perspective === TeamMember && (
            <div className="messageTypeContainer">
              <FontAwesomeIcon icon={getIcon(type ?? messageType!) as any} />
            </div>
          )}
          <div className="content">
            {!isRvm && perspective === Prospect && body && body.length > EXPAND_AT && (
              <div
                style={{ marginRight: '20px' }}
              >
                <FontAwesomeIcon icon={faExpand as any} onClick={internalToggle} />
              </div>
            )}
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxHeight: '150px',
              }}
            >
              {body}
            </div>
            {!isRvm && perspective !== Prospect && body && body.length > EXPAND_AT && (
              <div
                style={{ marginLeft: '20px' }}
              >
                <FontAwesomeIcon icon={faExpand as any} onClick={internalToggle} />
              </div>
            )}
          </div>
          {perspective === Prospect && (
            <div className="messageTypeContainer">
              <FontAwesomeIcon icon={getIcon(type ?? messageType!) as any} />
            </div>
          )}
        </div>
      </div>
      <Modal
        centered
        isOpen={isOpen}
        toggle={internalToggle}
        style={{ minWidth: '50vw' }}
      >
        <ModalHeader toggle={internalToggle} />
        <ModalBody style={{ maxHeight: '75vh', overflow: 'scroll' }}>
          {body}
        </ModalBody>
      </Modal>
    </>
  );
};

export default MessageItem;
