const getCurrentTenantDomain = () => {
  const location = window.location.host;
  const parts = window.location.host.split('.');

  let isDefault = false;

  if (
    parts.length <= 2
    || ['dev', 'staging', 'demo', 'app'].includes(parts[0].toLowerCase())
  ) {
    isDefault = true;
  }

  return {
    location,
    isDefault,
  };
};

export default getCurrentTenantDomain;
