import React from 'react';
import { Navigate } from 'react-router-dom';

import Paywall from './Paywall';

import { useStoreState } from '../store';
import { NON_AUTHORIZED_REDIRECT_URL } from '../../constants';

const PrivateRoute = ({ children }: { children: any }) => {
  const isAuthenticated = useStoreState((state) => state.authentication.isAuthenticated);

  return isAuthenticated
    ? (
      <>
        <Paywall />
        {children}
      </>
    ) : <Navigate to={NON_AUTHORIZED_REDIRECT_URL} />;
};

export default PrivateRoute;
