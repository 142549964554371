import React, { useState } from 'react';
import { NavLink as RouteNavLink } from 'react-router-dom';
import {
  Label,
  Nav,
  NavbarBrand,
  NavLink,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserAlt,
  faTimes,
  faBars,
} from '@fortawesome/free-solid-svg-icons';

import { DEFAULT_LOGO_IMAGE } from '../../../constants';
import { Tenant } from '../../../types';

const TabletNav = ({
  tenant,
  friendlyName,
  isImpersonating,
  signout,
  endImpersonation,
}: {
  tenant: Tenant | null,
  friendlyName: string,
  isImpersonating: boolean,
  signout: Function,
  endImpersonation: Function,
}) => {
  const logo = tenant?.logoUrl || DEFAULT_LOGO_IMAGE;

  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <Nav className="d-md-none justify-content-between align-items-center w-100">
      <NavbarBrand
        id="whiteLabel"
        className="d-xs d-md-none"
        style={{ marginRight: '0px' }}
      >
        <img id="whiteLabelImage" alt="Main Logo" src={logo} />
      </NavbarBrand>
      <div className="d-flex align-items-center justify-content-end">
        <div className={`d-flex align-items-center justify-content-center ${isImpersonating ? 'visible' : 'invisible'}`}>
          <FontAwesomeIcon
            id="viewAsEndBtn"
            className="bg-danger text-white rounded mx-1"
            icon={faTimes as any}
            onClick={() => endImpersonation()}
          />
          <Label className="bg-danger text-white m-0 rounded px-1">
            Viewing As:
          </Label>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <FontAwesomeIcon
            className="m-2 justify-content-end text-primary"
            icon={faUserAlt as any}
          />
          <span className="mb-1 userNameMobile">{friendlyName}</span>
          <Dropdown
            isOpen={showDropdown}
            toggle={() => setShowDropdown(!showDropdown)}
          >
            <DropdownToggle nav>
              <FontAwesomeIcon
                className="text-secondary"
                size="lg"
                icon={faBars as any}
                id="menuIcon"
              />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <NavLink
                  to="/campaigns"
                  activeClassName="activeDrop"
                  tag={RouteNavLink}
                >
                  Campaigns
                </NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink
                  to="/leads"
                  activeClassName="activeDrop"
                  tag={RouteNavLink}
                >
                  Leads
                </NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink
                  to="/prospects"
                  activeClassName="activeDrop"
                  tag={RouteNavLink}
                >
                  Prospects
                </NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink
                  to="/team"
                  activeClassName="activeDrop"
                  tag={RouteNavLink}
                >
                  My Team
                </NavLink>
              </DropdownItem>
              <DropdownItem>
                <hr style={{ margin: '0px' }} />
              </DropdownItem>
              <DropdownItem>
                <NavLink
                  to="/profile"
                  activeClassName="active"
                  tag={RouteNavLink}
                >
                  My Profile
                </NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink
                  to="/subscription"
                  activeClassName="active"
                  tag={RouteNavLink}
                >
                  My Subscription
                </NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink
                  href="#"
                  onClick={() => signout()}
                >
                  Logout
                </NavLink>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </Nav>
  );
};

export default TabletNav;
