import React, { useEffect, useRef, useState } from 'react';
import {
  Spinner,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
} from 'reactstrap';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import capitalize from 'lodash.capitalize';

import CardForm from '../components/CardForm';
import TopLoader from '../components/TopLoader';

import { Product } from '../../types';
import { TOP_LOAD_RATE, TOP_LOAD_START } from '../../constants';

import { isDefaultState } from '../../utils/apiCallState';

import { useStoreActions, useStoreState } from '../store';

import '../../styles/payment.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!);

const Payment = () => {
  const ref = useRef(null);

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const products = useStoreState((state) => state.subscription.productList);
  const subscription = useStoreState((state) => state.subscription.subscription);

  const profileState = useStoreState((state) => state.profile.userState);
  const productListState = useStoreState((state) => state.subscription.productListState);
  const subscriptionState = useStoreState((state) => state.subscription.subscriptionState);

  const getBaseProducts = useStoreActions((actions) => actions.subscription.getBaseProducts);

  const mySubscribedProduct = (subscription
    && subscription.active
    && products.find((p) => p.name === subscription.name)) || null;

  const mySelection = selectedProduct ?? mySubscribedProduct;

  const showCC = selectedProduct
    && (!mySubscribedProduct || selectedProduct._id !== mySubscribedProduct._id);

  useEffect(() => {
    if (productListState.pending) {
      (ref.current as any).continuousStart(
        TOP_LOAD_START,
        TOP_LOAD_RATE,
      );
    }

    if (productListState.success || productListState.failure) (ref.current as any).complete();
  }, [productListState]);

  useEffect(() => {
    if (isDefaultState(productListState)) getBaseProducts();
  }, [productListState]);

  if (profileState.pending || subscriptionState.pending) {
    return <Spinner />;
  }

  return (
    <div className="payment">
      <TopLoader reference={ref} />
      <Elements stripe={stripePromise}>
        {subscription && subscription.active ? (
          <>
            <Row className="bg-primary text-white">
              <Col>
                <h2 className="p-2">Get more touchpoints!</h2>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm="3" />
              <Col sm="6">
                <h4> Upgrade your plan now</h4>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="bg-primary text-white">
              <Col>
                <h2 className="p-2">Join Go Fetch It!</h2>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm="3" />
              <Col sm="6">
                <h4>Sign up for access</h4>
              </Col>
            </Row>
          </>
        )}
        <Row className="mt-4">
          <Col sm="2" />
          <Col sm="8">
            <Row>
              {products.map((product) => (
                <Col key={product._id} sm="3" className="mt-4">
                  <Card
                    className={
                      mySelection && mySelection._id === product._id
                        ? 'selected'
                        : ''
                    }
                  >
                    <CardHeader>
                      {`${capitalize(product.name)} Subscription`}
                    </CardHeader>
                    <CardBody style={{ height: '15rem' }}>
                      <div>
                        <h4>{`$${product.price} per month`}</h4>
                        <p className="font-italic">
                          {`${product.defaultCustomerInteractions} Touchpoints`}
                        </p>
                        <span>Features</span>
                        <ul>
                          {product.defaultFeatures.map((feature) => (
                            <li key={`${product._id}-${feature}`}>
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <Button
                        color="primary"
                        onClick={() => setSelectedProduct(product)}
                      >
                        {`Select${
                          selectedProduct && selectedProduct._id === product._id
                            ? 'ed'
                            : ''
                        }`}
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        {showCC && (
          <Row className="mt-4">
            <Col sm="2" />
            <Col sm="4">
              <CardForm product={selectedProduct} />
            </Col>
          </Row>
        )}
      </Elements>
    </div>
  );
};

export default Payment;
