import React, { useState } from 'react';
import {
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import {
  faCog,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OptionsDropdown = ({
  name,
  onDelete,
  md = false,
  hidden = false,
}: {
  name: string,
  md?: boolean,
  hidden?: boolean,
  onDelete: () => void,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {!hidden && (
        <Col className={`
          campaign-settings
          col-auto
          flyout-container
          border-left
          border-secondary
          justify-content-center
          align-items-center
          ${md ? 'd-md-none' : 'd-none d-md-flex'}
          `}
        >
          <Dropdown
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
          >
            <DropdownToggle>
              <FontAwesomeIcon icon={faCog as any} className="cog text-secondary" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={onDelete}>
                <FontAwesomeIcon icon={faTrashAlt as any} className="mr-2" />
                {` Delete ${name}`}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>
      )}
    </>
  );
};

export default OptionsDropdown;
