import http, { authEndpointUrl, publicEndpointUrl } from './http';
import { APIListQuery, User, UserMetadata } from '../types';

export default {
  create: (params: User) => http
    .post(authEndpointUrl('users'), params)
    .then((response) => response.data),
  find: (params: APIListQuery) => http
    .get(authEndpointUrl('users'), { params })
    .then((response) => ({ response: response.data, params })),
  register: (user: User) => http
    .post(publicEndpointUrl('users'), user)
    .then((response) => ({ response })),
  tenant: (id: string) => http
    .get(authEndpointUrl(`users/${id}/tenant`)).then((response) => response.data),
  profile: () => http
    .get(authEndpointUrl('users/profile')).then((response) => response.data),
  updateProfile: (data: User) => http
    .put(authEndpointUrl('users/profile'), data).then((response) => response.data),
  patchProfile: (tenantId: string) => http
    .patch(authEndpointUrl('users/profile'), { tenantId }).then((response) => response.data),
  patchProfileMeta: (params: UserMetadata) => http
    .patch(authEndpointUrl('users/profile/meta'), params).then((response) => response.data),
};
