import { action, Action } from 'easy-peasy';

import { User } from '../../types';

export interface ImpersonationModel {
  user: User | null;
  isImpersonating: boolean;
  setImpersonation: Action<ImpersonationModel, User>;
  resetImpersonation: Action<ImpersonationModel>;
}

const impersonation = (): ImpersonationModel => ({
  user: null,
  isImpersonating: false,
  setImpersonation: action((state, user) => {
    state.user = user;
    state.isImpersonating = true;
  }),
  resetImpersonation: action((state) => {
    state.user = null;
    state.isImpersonating = false;
  }),
});

export default impersonation;
