import React, { useEffect, useRef } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import {
  Container,
  Col,
  Row,
  Button,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import TopLoader from '../components/TopLoader';
import ListHeader from '../components/ListHeader';
import CampaignListItem from '../components/CampaignListItem';

import usedAllocation from '../../utils/usedAllocation';
import { CAMPAIGN_BACKGROUND_IMAGE, TOP_LOAD_RATE, TOP_LOAD_START } from '../../constants';

import { useStoreState, useStoreActions } from '../store';

const Campaigns = () => {
  const ref = useRef(null);

  const navigate = useNavigate();

  const subscription = useStoreState((state) => state.subscription.subscription);

  const campaigns = useStoreState((state) => state.campaign.campaignList);
  const campaignListState = useStoreState((state) => state.campaign.campaignListState);

  const {
    user: impersonatedUser,
    isImpersonating,
  } = useStoreState((state) => state.impersonation);
  const profileId = useStoreState((state) => state.profile.user?._id);

  const userId = isImpersonating ? impersonatedUser?._id : profileId;

  const getCampaigns = useStoreActions((actions) => actions.campaign.getCampaigns);

  const refreshCampaigns = () => getCampaigns({ userId });

  useEffect(() => refreshCampaigns(), [userId]);

  useEffect(() => {
    if (campaignListState.pending) {
      (ref.current as any).continuousStart(
        TOP_LOAD_START,
        TOP_LOAD_RATE,
      );
    }

    if (campaignListState.success || campaignListState.failure) (ref.current as any).complete();
  }, [campaignListState]);

  return (
    <Container className="d-flex justify-content-center">
      <TopLoader reference={ref} />
      <Col style={{ width: '90vw' }}>
        <ListHeader
          display={campaignListState.success && !campaigns.length}
          background={{
            image: CAMPAIGN_BACKGROUND_IMAGE,
            description: 'Empty Campaign List',
          }}
          link={(
            <p>
              {'No records to display. Get started by '}
              <NavLink to="/campaigns/new">adding a campaign</NavLink>
              {' now!'}
            </p>
          )}
        />
        <Row>
          <Button
            id="buttonAddCampaign"
            // eslint-disable-next-line max-len
            disabled={isImpersonating || usedAllocation(subscription?.limits.campaigns)}
            onClick={() => navigate('/campaigns/new')}
            outline
            color="secondary"
            className="col-12 mx-0 mt-4 pl-0 d-flex align-items-center justify-content-start"
          >
            <FontAwesomeIcon icon={faPlusCircle as any} className="mx-4" />
            <span>Add Campaign</span>
          </Button>
        </Row>
        {campaignListState.success
          && campaigns.map((campaign) => (
            <CampaignListItem
              key={campaign._id}
              campaign={campaign}
              inImpersonationMode={isImpersonating}
              refreshCampaigns={refreshCampaigns}
            />
          ))}
      </Col>
    </Container>
  );
};

export default Campaigns;
