import React from 'react';
import { Navigate } from 'react-router-dom';

import { useStoreState } from '../store';
import { LOGGED_IN_REDIRECT_URL } from '../../constants';

const GuideRoute = ({ children }: { children: any }) => {
  const isAuthenticated = useStoreState((state) => state.authentication.isAuthenticated);

  return !isAuthenticated ? children : <Navigate to={LOGGED_IN_REDIRECT_URL} />;
};

export default GuideRoute;
