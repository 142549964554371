import http from './http';
import campaigns from './campaigns';
import messages from './messages';
import prospects from './prospects';
import rvm from './rvm';
import subscription from './subscription';
import teams from './teams';
import tenants from './tenants';
import users from './users';

export default {
  auth: {
    setBearerToken: (token: string) => {
      // Add auth credentials to all outgoing API requests.
      http.interceptors.request.use(
        async (config) => {
          // eslint-disable-next-line no-param-reassign
          if (!!config && !!config.headers) (config.headers.common as any).authorization = `Bearer ${token}`;

          return config;
        },
        (error) => Promise.reject(error),
      );
    },
  },
  campaigns,
  messages,
  prospects,
  rvm,
  subscription,
  teams,
  tenants,
  users,
};
