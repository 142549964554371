import {
  action,
  Action,
  thunk,
  Thunk,
} from 'easy-peasy';
import { StoreModel } from '.';
import { DEFAULT_API_CALL_STATE, DEFAULT_QUERY_LIMIT } from '../../constants';

import api from '../../api';
import {
  APICallState,
  ToastType,
  User,
  UserRole,
} from '../../types';

export interface UserListQuery {
  userId: string;
  tenantId: string;
  includeAvgResponse: boolean;
  role: UserRole | { $in: UserRole[] };
}

enum APICall {
  userList,
  create,
}

export interface UserModel {
  // State
  userList: User[];
  userListState: APICallState;
  createState: APICallState;
  // Setters
  setUserList: Action<UserModel, User[]>;
  setAPICallState: Action<UserModel, { apiCall: APICall, value: APICallState }>;
  // Methods
  getUsers: Thunk<UserModel, UserListQuery>;
  createUser: Thunk<UserModel, User, null, StoreModel>;
}

const user = (): UserModel => ({
  userList: [],
  userListState: { ...DEFAULT_API_CALL_STATE },
  createState: { ...DEFAULT_API_CALL_STATE },
  setUserList: action((state, userList) => {
    state.userList = userList;
  }),
  setAPICallState: action((state, data) => {
    // eslint-disable-next-line default-case
    switch (data.apiCall) {
      case APICall.userList:
        state.userListState = data.value;
        break;
      case APICall.create:
        state.createState = data.value;
        break;
    }
  }),
  getUsers: thunk(
    async ({ setUserList, setAPICallState }, query) => {
      setAPICallState({
        apiCall: APICall.userList,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        const result = await api.users.find({
          skip: 0,
          limit: DEFAULT_QUERY_LIMIT,
          ordering: '-createdAt',
          filter: { ...query },
        });

        setUserList(result.response.data);
        setAPICallState({
          apiCall: APICall.userList,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.userList,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });
      }
    },
  ),
  createUser: thunk(
    async ({ setAPICallState }, data, helpers) => {
      const { meta: { notify } } = helpers.getStoreActions();

      setAPICallState({
        apiCall: APICall.create,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        await api.users.create(data);

        setAPICallState({
          apiCall: APICall.create,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });

        notify({
          message: 'Created a new team member',
          type: ToastType.success,
        });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.create,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });

        notify({
          message: 'Failed to create a new team member',
          type: ToastType.error,
        });
      }
    },
  ),
});

export default user;
