import React from 'react';
import { Badge } from 'reactstrap';
import { useStoreState } from '../store';

const ProspectAlertBadge = () => {
  const pendingResponseState = useStoreState((state) => state.prospect.pendingResponseState);
  const pendingResponseCount = useStoreState((state) => state.prospect.pendingResponseCount);

  return (
    <>
      {(pendingResponseState.success || !!pendingResponseCount) && pendingResponseCount !== 0 && (
        <Badge
          color="danger"
          style={{
            position: 'absolute',
            top: pendingResponseCount > 9 ? '-10px' : '-7px',
            right: pendingResponseCount > 9 ? '-4px' : '-5px',
            borderRadius: '50px',
            padding: '0.5em 0.7em',
            fontWeight: 'normal',
          }}
        >
          {pendingResponseCount}
        </Badge>
      )}
    </>
  );
};

export default ProspectAlertBadge;
