import http, { authEndpointUrl } from './http';
import { APIListQuery, Message } from '../types';

export default {
  create: (data: Message) => http
    .post(authEndpointUrl('messages'), data)
    .then((response) => response.data),
  find: (params: APIListQuery) => http
    .get(authEndpointUrl('messages'), { params })
    .then((response) => ({ response: response.data, params })),
  findById: (id: string) => http
    .get(authEndpointUrl(`messages/${id}`))
    .then((response) => response.data),
};
