import moment from 'moment';

import { ResponseTime } from '../types';

const calculateAverageResponseTime = (responseTimes?: ResponseTime[]) => {
  if (!responseTimes) return 0;

  const times: number[] = responseTimes
    .filter((rt) => rt.endTime != null)
    .map((rt) => moment(rt.endTime).diff(moment(rt.startTime)));

  const totalResponseTime = times.reduce((total, time) => total + time, 0);
  const totalResponseTimes = responseTimes.length;

  return totalResponseTime / totalResponseTimes;
};

export default calculateAverageResponseTime;
