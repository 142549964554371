import http, { authEndpointUrl, publicEndpointUrl } from './http';

export default {
  getMySubscription: () => http
    .get(authEndpointUrl('subscription'))
    .then((response) => response.data),
  cancel: () => http
    .delete(authEndpointUrl('subscription')),
  create: (paymentMethodId: string, priceId: string) => http
    .post(authEndpointUrl('subscription'), {
      paymentMethodId,
      priceId,
    })
    .then((r) => ({ data: r.data })),
  upgrade: (priceId: string) => http
    .put(authEndpointUrl('subscription'), {
      priceId,
    })
    .then((r) => ({ data: r.data })),
  product: {
    findBase: () => http
      .get(publicEndpointUrl('base-products'))
      .then((response) => response.data),
  },
};
