import http, { authEndpointUrl } from './http';
import { APIListQuery, Campaign } from '../types';

export default {
  create: (data: Campaign) => http
    .post(authEndpointUrl('campaigns'), data)
    .then((response) => response.data),
  find: (params: APIListQuery) => http
    .get(authEndpointUrl('campaigns'), { params })
    .then((response) => ({ response: response.data, params })),
  findById: (id: string) => http
    .get(authEndpointUrl(`campaigns/${id}`))
    .then((response) => response.data),
  update: (id: string, data: Campaign) => http
    .put(authEndpointUrl(`campaigns/${id}`), data)
    .then((response) => response.data),
  delete: (id: string) => http
    .delete(authEndpointUrl(`campaigns/${id}`))
    .then((response) => response.data),
};
