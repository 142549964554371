import http, { authEndpointUrl } from './http';
import { APIListQuery, Lead } from '../types';

export default {
  create: (data: Lead) => http
    .post(authEndpointUrl('leads'), data)
    .then((response) => response.data),
  find: (params: APIListQuery) => http
    .get(authEndpointUrl('prospects'), { params })
    .then((response) => ({ response: response.data, params })),
  findById: (id: string) => http
    .get(authEndpointUrl(`prospects/${id}`))
    .then((response) => response.data),
  getPendingResponseCount: () => http
    .get(authEndpointUrl('prospects/pending-response-count'))
    .then((response) => response.data),
};
