import http, { authEndpointUrl } from './http';
import { APIListQuery, Team } from '../types';

export default {
  create: (params: Team) => http
    .post(authEndpointUrl('teams'), params)
    .then((response) => response.data),
  find: (params: APIListQuery) => http
    .get(authEndpointUrl('teams'), { params })
    .then((response) => ({ response: response.data, params })),
  findByUserId: (userId: string) => http
    .get(authEndpointUrl(`users/${userId}/team`)).then((response) => ({ response: response.data })),
  update: (id: string, params: Team) => http
    .put(authEndpointUrl(`teams/${id}`), params)
    .then((response) => ({ response: response.data, params })),
};
