import React from 'react';
import {
  Col,
  Input,
  InputGroup,
  Row,
} from 'reactstrap';

import { Touchpoint } from '../../../../types';
import { MIN_MINUTES_INTERVAL_VALUE, MAX_INTERVAL_VALUE } from '../../../../constants';

const IntervalBar = ({
  visible,
  disabled,
  formik,
  captureInput,
}: {
  visible: boolean;
  disabled?: boolean;
  formik: {
    values: Touchpoint,
    errors: any,
    touched: any,
    handleBlur: (e: React.FocusEvent<any, Element>) => void,
  },
  captureInput: (target: string, value: any) => void;
}) => {
  const {
    values: {
      intervalTime: {
        interval,
        timeType,
      },
    },
    errors,
    touched,
    handleBlur,
  } = formik;

  const focusSelect = (e: any) => e.target.select();

  return (
    <>
      {visible && (
        <Row className="add-touchpoint flex-nowrap border border-secondary rounded p-0">
          <Col className="col-auto text-muted d-flex align-items-center p-2 ">
            Wait&nbsp;
            <InputGroup>
              <Input
                type="number"
                name="interval"
                disabled={disabled}
                min={timeType === 'minutes' ? MIN_MINUTES_INTERVAL_VALUE : 1}
                max={MAX_INTERVAL_VALUE}
                onChange={(e) => captureInput('interval', e.target.value)}
                onBlur={handleBlur}
                onFocus={focusSelect}
                value={interval}
                invalid={touched.intervalTime?.interval && !!errors.intervalTime?.interval}
              />
            </InputGroup>
            &nbsp;
            <InputGroup>
              <Input
                className="text-muted border border-muted"
                style={{ minWidth: '100px' }}
                type="select"
                name="timeType"
                disabled={disabled}
                onChange={(e) => captureInput('timeType', e.target.value)}
                onBlur={handleBlur}
                value={timeType}
                invalid={touched.intervalTime?.timeType && !!errors.intervalTime?.timeType}
              >
                <option value="minutes">
                  minute
                  {interval !== 1 ? 's' : ''}
                </option>
                <option value="hours">
                  hour
                  {interval !== 1 ? 's' : ''}
                </option>
                <option value="days">
                  day
                  {interval !== 1 ? 's' : ''}
                </option>
              </Input>
            </InputGroup>
            &nbsp;Then
          </Col>
        </Row>
      )}
    </>
  );
};

export default IntervalBar;
