import React, { Suspense, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import isEmpty from 'lodash.isempty';

import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';

// Components
import Header from './components/Header';
import Loading from './components/Loading';
import GuideRoute from './components/GuideRoute';
import PrivateOutlet from './components/PrivateOutlet';
import PrivateRoute from './components/PrivateRoute';

// Pages
import Campaign from './pages/Campaign';
import Campaigns from './pages/Campaigns';
import CreateCampaign from './pages/CreateCampaign';
import CreateTeam from './pages/CreateTeam';
import CreateUser from './pages/CreateUser';
import EditCampaign from './pages/EditCampaign';
import Entry from './pages/Entry';
import Leads from './pages/Leads';
import Messages from './pages/Messages';
import Payment from './pages/Payment';
import Profile from './pages/Profile';
import Prospects from './pages/Prospects';
import Signup from './pages/Signup';
import Subscription from './pages/Subscription';
import Users from './pages/Users';

import lock from '../utils/lock';
import { isDefaultState } from '../utils/apiCallState';
import getCurrentTenantDomain from '../utils/whitelabel';
import { NON_AUTHORIZED_REDIRECT_URL } from '../constants';

import { useStoreActions, useStoreState } from './store';

import 'react-toastify/dist/ReactToastify.css';

// TODO: Not handling tabulated data
// TODO: Show loading indicator if waiting for profile or tenant
// TODO: We don't reset the screen when we leave lead messages for example
const App = () => {
  const tenant = useStoreState((state) => state.tenant.tenant);
  const tenantState = useStoreState((state) => state.tenant.tenantState);

  const getTenant = useStoreActions((actions) => actions.tenant.getTenant);
  const getTenants = useStoreActions((actions) => actions.tenant.getTenants);
  // eslint-disable-next-line max-len
  const startAuthenticatedSession = useStoreActions((actions) => actions.authentication.startAuthenticatedSession);

  const faviconUrl = tenantState.pending
    && tenant
    && (tenant?.faviconUrl ?? '/favicon.ico');
  const title = tenantState.pending ? tenant?.name : 'Go Fetch It';

  // eslint-disable-next-line max-len
  // TODO: Broken - pulls the wrong users' session / will pull the most recently logged-in users' session
  // lock.checkSession({}, (error, authResult) => {
  //   if (authResult) {
  //     startAuthenticatedSession({ isAuthenticated: true, token: authResult.accessToken });
  //   }

  //   console.log(error, authResult);
  // });

  // Get tenant
  useEffect(() => {
    (async () => {
      if (isDefaultState(tenantState)) {
        const domain = getCurrentTenantDomain();

        try {
          if (isEmpty(tenant) || tenant?.domain !== domain.location) {
            await getTenant(domain.location);
            if (domain.isDefault) getTenants();
          }
        } catch (err) {
          // do nothing for now. Just falls back to
          // normal functionality.
          console.log(err);
        }
      }
    })();
  }, [tenantState]);

  // eslint-disable-next-line no-undef
  const wrapComponent = (comp: JSX.Element) => (
    <>
      <Header />
      {comp}
    </>
  );

  /* eslint-disable react/jsx-one-expression-per-line */
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
      />
      <Helmet defaultTitle={title}>
        {/* eslint-disable-next-line react/no-invalid-html-attribute */}
        <link rel="shortcut icon" href={faviconUrl as string} />
      </Helmet>
      <Suspense fallback={Loading}>
        <BrowserRouter>
          <Routes>
            {/* Public/Unauthenticated Routes */}
            <Route path="/" element={<Navigate to={NON_AUTHORIZED_REDIRECT_URL} />} />
            <Route
              path={NON_AUTHORIZED_REDIRECT_URL}
              element={<GuideRoute><Entry /></GuideRoute>}
            />
            <Route path="/signup" element={<GuideRoute><Signup /></GuideRoute>} />

            {/* Private Routes */}
            <Route path="/profile" element={wrapComponent(<PrivateRoute><Profile /></PrivateRoute>)} />
            <Route path="/campaigns" element={wrapComponent(<PrivateOutlet />)}>
              <Route path="" element={<Campaigns />} />
              <Route path="/campaigns/new" element={<CreateCampaign />} />
              <Route path="/campaigns/:id" element={<Campaign />} />
              <Route path="/campaigns/:id/edit" element={<EditCampaign />} />
            </Route>
            <Route path="/leads" element={wrapComponent(<PrivateOutlet />)}>
              <Route path="" element={<Leads />} />
              <Route path="/leads/:id" element={<Messages isProspect={false} />} />
            </Route>
            <Route path="/prospects" element={wrapComponent(<PrivateOutlet />)}>
              <Route path="" element={<Prospects />} />
              <Route path="/prospects/:id" element={<Messages />} />
            </Route>
            <Route path="/team" element={wrapComponent(<PrivateOutlet />)}>
              <Route path="" element={<Users />} />
              <Route path="/team/new" element={<CreateTeam />} />
              <Route path="/team/new/member" element={<CreateUser />} />
            </Route>
            <Route path="/payment" element={wrapComponent(<PrivateRoute><Payment /></PrivateRoute>)} />
            <Route path="/subscription" element={wrapComponent(<PrivateRoute><Subscription /></PrivateRoute>)} />
            <Route
              path="*"
              element={(
                <main style={{ padding: '1rem' }}>
                  <p>There is nothing here!</p>
                  {/* Trigger build */}
                </main>
              )}
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  );
  /* eslint-enable react/jsx-one-expression-per-line */
};

export default App;
