import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import moment from 'moment';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import calculateAverageResponseTime from '../../utils/calculateAverageResponseTime';

import { Prospect } from '../../types';

const ProspectListItem = ({ prospect }: { prospect: Prospect }) => {
  const { responseTimes } = prospect;

  const averageResponseTime = calculateAverageResponseTime(responseTimes);
  const averageResponseTimeFormat = averageResponseTime === 0
    ? 'No response'
    : moment.duration(averageResponseTime).humanize();

  const lastResponse = responseTimes?.at(-1);
  const awaitingResponse = lastResponse != null && lastResponse.endTime == null;

  return (
    <Row
      className={`flex border border-${
        awaitingResponse ? 'danger' : 'secondary'
      } rounded mb-4`}
    >
      <Col className="pl-3">
        <Row className="py-3 d-flex align-items-center">
          <Col className="d-flex align-items-center">
            <p className="listItemName text-primary">
              {`${prospect.firstName} ${prospect.lastName}`}
            </p>
          </Col>
        </Row>
      </Col>
      <Col className="pl-3">
        <Row className="py-3 d-flex align-items-center">
          <Col className="d-flex align-items-center">
            <p className="listItemName text-primary">{prospect.campaign?.name ?? 'Unknown'}</p>
          </Col>
        </Row>
      </Col>
      <Col className="pl-3">
        <Row className="py-3 d-flex align-items-center">
          <Col className="d-flex align-items-center justify-content-center">
            <p className="listItemName text-primary">{averageResponseTimeFormat}</p>
          </Col>
        </Row>
      </Col>
      <Col className="pl-3">
        <Row className="py-3 d-flex align-items-center">
          <Col className="d-flex align-items-center">
            <p className="listItemName text-primary">{awaitingResponse ? 'Awaiting Response' : 'Hasn\'t responded'}</p>
          </Col>
        </Row>
      </Col>
      <Col className="col-auto d-flex justify-content-center align-items-center">
        <Link
          to={`/prospects/${prospect._id}`}
        >
          <FontAwesomeIcon icon={faChevronRight as any} />
        </Link>
      </Col>
    </Row>
  );
};

export default ProspectListItem;
