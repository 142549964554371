import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Alert,
  Col,
  Row,
  Form,
  FormGroup,
} from 'reactstrap';

import useQuery from '../hooks/useQuery';
import { useStoreActions, useStoreState } from '../store';

import { DEFAULT_LOGO_IMAGE } from '../../constants';

import lock from '../../utils/lock';

// Change to entry.scss
import '../../styles/entry.scss';

const Entry = () => {
  const tenant = useStoreState((state) => state.tenant.tenant);
  // eslint-disable-next-line max-len
  const startAuthenticatedSession = useStoreActions((actions) => actions.authentication.startAuthenticatedSession);

  const logo = tenant?.logoUrl || DEFAULT_LOGO_IMAGE;
  const pageStyle = tenant?.backgroundColor
    ? { backgroundColor: tenant?.backgroundColor }
    : {};

  const background = tenant?.backgroundUrl
    ? { backgroundImage: `url(${tenant?.backgroundUrl})` }
    : pageStyle;

  lock.on('authenticated', (authResult) => {
    startAuthenticatedSession({ isAuthenticated: true, token: authResult.accessToken });
  });

  const query = useQuery();
  const isVerified = query.get('verified') === '1';

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      lock.show();
    }
  };

  useEffect(() => {
    lock.show();
  }, [lock]);

  return (
    <Col id="loginPage" style={pageStyle}>
      <div id="loginContainer" style={background}>
        <img id="loginLogo" alt="Main Logo" src={logo} />
        <Form id="loginForm" onKeyDown={(e) => handleKeyDown(e)}>
          <FormGroup>
            <Row>
              <Col className="loginButtonContainer">
                {isVerified && (
                  <Alert>
                    <h4>Account verified!</h4>
                    <p>Log in to get started</p>
                  </Alert>
                )}
                <div id="login" />
              </Col>
            </Row>
            <Row>
              <Col className="loginButtonContainer">
                <NavLink id="signUp" to="/signup">Sign Up</NavLink>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </div>
    </Col>
  );
};

export default Entry;
