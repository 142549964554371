import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { Lead } from '../../types';

const LeadListItem = ({ lead }: { lead: Lead }) => (
  <Row className="flex border border-secondary rounded mb-4">
    <Col className="pl-3">
      <Row className="py-3 d-flex align-items-center">
        <Col className="d-flex align-items-center">
          <p className="listItemName text-primary">
            {`${lead.firstName} ${lead.lastName}`}
          </p>
        </Col>
      </Row>
    </Col>
    <Col className="pl-3">
      <Row className="py-3 d-flex align-items-center">
        <Col className="d-flex align-items-center">
          <p className="listItemName text-primary">{lead.campaign?.name ?? 'Unknown'}</p>
        </Col>
      </Row>
    </Col>
    <Col className="col-auto d-flex justify-content-center align-items-center">
      <Link to={`/leads/${lead._id}`}>
        <FontAwesomeIcon icon={faChevronRight as any} />
      </Link>
    </Col>
  </Row>
);

export default LeadListItem;
