import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Col } from 'reactstrap';

import _isEmpty from 'lodash.isempty';

import TopLoader from '../components/TopLoader';
import CampaignForm from '../components/CampaignForm';

import usedAllocation from '../../utils/usedAllocation';
import { TOP_LOAD_RATE, TOP_LOAD_START } from '../../constants';

import { useStoreState, useStoreActions } from '../store';

const CreateCampaign = () => {
  const ref = useRef(null);
  const navigate = useNavigate();

  const createState = useStoreState((state) => state.campaign.createState);
  const subscription = useStoreState((state) => state.subscription.subscription);
  const isImpersonating = useStoreState((state) => state.impersonation.isImpersonating);

  const reset = useStoreActions((actions) => actions.campaign.reset);
  const createCampaign = useStoreActions((actions) => actions.campaign.createCampaign);

  const returnToCampaigns = () => navigate('/campaigns');

  if (isImpersonating) returnToCampaigns();

  useEffect(() => {
    if (createState.pending) {
      (ref.current as any).continuousStart(
        TOP_LOAD_START,
        TOP_LOAD_RATE,
      );
    }

    if (createState.success) {
      (ref.current as any).complete();

      reset();
      returnToCampaigns();
    } else if (createState.failure) (ref.current as any).complete();
  }, [createState]);

  useEffect(() => {
    // eslint-disable-next-line max-len
    if (usedAllocation(subscription?.limits.campaigns)) returnToCampaigns();
  }, [subscription]);

  return (
    <Container className="d-flex justify-content-center">
      <TopLoader reference={ref} />
      <Col style={{ width: '90vw' }}>
        <CampaignForm
          onSubmit={(data) => createCampaign(data)}
          onCancel={returnToCampaigns}
          features={subscription?.features ?? ['email', 'sms']}
        />
      </Col>
    </Container>
  );
};

export default CreateCampaign;
