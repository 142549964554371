import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import '../../styles/list.scss';

const SetupLeads = () => (
  <div className="m-5 text-center">
    <strong>To connect your Campaigns to a stream of incoming Leads...</strong>
    <Row>
      <Col className="lead-steps" md="2" />
      <Col md="7" className="leads-list lead-steps">
        <div>
          <div className="step-one">One Time Setup</div>
          <li className="leads-border-primary">Create one or more Campaigns</li>
          <li className="leads-border-primary">
            <span>Create a Zapier account by </span>
            <a
              href="https://zapier.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              clicking here.
            </a>
          </li>
          <li className="leads-border-primary">
            <span>
              If your GoFetchit invitation has been sent ( by GoFetch.It ) then
              accept it to use GoFetchIt as an app through Zapier. Otherwise,
              you can join by
            </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={process.env.REACT_APP_ZAPIER_APP_INVITE_LINK}
            >
              clicking here.
            </a>
          </li>
        </div>
        <div>
          <div className="step-two">
            Repeat these steps for each new Campaign you create
          </div>
          <li className="leads-border-secondary">
            Click the `Make a Zap!` button in the top right of your navbar
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>Click the when this happens tab</li>
              <li>
                Choose an app you would like your leads to be generated from
              </li>
              <li>
                Choose the trigger event that will generate your leads ( ‘ New
                User is usually preferred ‘ )
              </li>
              <li>Sign in to chosen app via Zapier</li>
              <li>
                Click Test &amp; Continue which will test that the connection
                works
              </li>
            </ol>
          </li>
          <li className="leads-border-secondary">
            Do This...
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>Search for GoFetch.it and select it as your chosen app</li>
              <li>
                In the dropdown for choose action event choose the create lead
                option and hit continue
              </li>
              <li>Sign in with GoFetch.it using your GoFetch.it credentials</li>
              <li>
                Customize Lead
                <ul>
                  <li>
                    Press the plus icon to the far right of each input and
                    select which option you would like to populate in this field
                  </li>
                  <li>
                    Make sure that the fields have data for the required fields.
                    If not, choose a different set of example data from the
                    &quot;When this happens.. - Find Data&quot; step.
                  </li>
                  <li>Click Test &amp; Continue</li>
                  <li>You&apos;re done! Whoopee</li>
                </ul>
              </li>
            </ol>
          </li>
        </div>
      </Col>
      <Col md="3">
        <div className="d-flex leads-contact-us align-items-center">
          <span className="pr-3 leads-mail-icon">
            <FontAwesomeIcon color="#29A3EB" icon={faEnvelope as any} />
          </span>
          <div className="text-align-left">
            <span>As always, reach out to </span>
            <a
              href="mailto:support@GoFetch.it"
              rel="noopener noreferrer"
              target="_blank"
            >
              support@GoFetch.it
            </a>
            <span> if you need any help</span>
          </div>
        </div>
      </Col>
    </Row>
  </div>
);

export default SetupLeads;
