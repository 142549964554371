import React, { useState } from 'react';
import {
  Row,
  Col,
  FormGroup,
  Input,
  InputGroup,
  FormFeedback,
} from 'reactstrap';

import noop from 'lodash.noop';
import cloneDeep from 'lodash.clonedeep';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import EmailInput from './EmailInput';
import IntervalBar from './IntervalBar';
import NameInput from './NameInput';
import OptionsDropdown from './OptionsDropdown';
import RVMInput from './RVMInput';

import {
  TOUCHPOINT_DEFAULT_MESSAGE,
  TOUCHPOINT_OPTIONS,
  MIN_MINUTES_INTERVAL_VALUE,
  MAX_INTERVAL_VALUE,
} from '../../../../constants';
import { Touchpoint, TouchpointType } from '../../../../types';

import '../../../../styles/campaign-touchpoint.scss';
import '../../../../styles/flyout.scss';

export const TouchpointSchema = () => Yup.object().shape({
  name: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  subject: Yup.string().test('isSubjectRequired', 'Required', function isSubjectRequired(value) {
    const type = this.parent.type as TouchpointType;

    // Subject is not needed unless it's an email
    return type === TouchpointType.Email || type === TouchpointType.FancyEmail
      ? Yup.string().required().isValid(value)
      : true;
  }),
  message: Yup.string().test('isMessageRequired', 'Required', function isMessageRequired(value) {
    const type = this.parent.type as TouchpointType;

    // Message is not needed for RVM
    return type !== TouchpointType.RVM
      ? Yup.string().required().isValid(value)
      : true;
  }),
  newFile: Yup.mixed().test('isNewFileRequired', 'Required', function isNewFileRequired(value) {
    const type = this.parent.type as TouchpointType;
    const rvmUrl = this.parent.rvmUrl as string;

    // New file is not required for non RVM touchpoints
    return type === TouchpointType.RVM && !rvmUrl
      ? !!value
      : true;
  }),
  intervalTime: Yup.object().shape({
    timeType: Yup.string().required('Required'),
    interval: Yup.number().min(0).max(MAX_INTERVAL_VALUE).required('Required'),
  }),
});

// TODO: Style the various components that are in this
/* eslint-disable max-len */
const TouchPointInput = ({
  index,
  touchpoint,
  features,
  previousTouchpointName,
  disabled,
  deleteTouchpoint,
  updateTouchpoint,
  // usingContentBuilder,
  // setContentBuilderTarget,
}: {
  index: number,
  touchpoint: Touchpoint,
  features: string[],
  previousTouchpointName: string | null,
  disabled?: boolean,
  deleteTouchpoint: () => void,
  updateTouchpoint: (touchpoint: Touchpoint) => void,
  // usingContentBuilder: boolean,
  // setContentBuilderTarget: Function,
}) => {
  const formik = useFormik({
    validationSchema: TouchpointSchema,
    initialValues: {
      type: touchpoint.type,
      name: touchpoint.name,
      message: touchpoint.message,
      subject: touchpoint.subject,
      rvmUrl: touchpoint.rvmUrl,
      newFile: null,
      intervalTime: touchpoint.intervalTime,
    },
    onSubmit: noop,
  });

  const {
    values: {
      name,
      type,
      message,
      intervalTime: { timeType, interval },
      rvmUrl,
    },
    errors,
    touched,
    handleBlur,
    setFieldValue,
  } = formik;

  const [displayKeywords, setDisplayKeywords] = useState(false);

  const keywordsOnFocus = () => setDisplayKeywords(!displayKeywords);

  const validInterval = (interval: string, type: string) => {
    const parsedValue = parseInt(interval, 10);
    const MAX_VALUE = MAX_INTERVAL_VALUE;

    let intervalValue = parsedValue;

    if (!intervalValue || intervalValue < 0) {
      intervalValue = 1;
    }

    if (intervalValue > MAX_VALUE) {
      intervalValue = MAX_VALUE;
    }

    if (type === 'minutes' && intervalValue < MIN_MINUTES_INTERVAL_VALUE) {
      intervalValue = 5;
    }

    return intervalValue;
  };

  const captureInput = (target: string, value: any) => {
    const newTouchpoint = cloneDeep(touchpoint);

    switch (target) {
      case 'timeType':
        setFieldValue('intervalTime.timeType', value);
        newTouchpoint.intervalTime.timeType = value;

        if (value === 'minutes' && newTouchpoint.intervalTime.interval < MIN_MINUTES_INTERVAL_VALUE) {
          setFieldValue('intervalTime.interval', MIN_MINUTES_INTERVAL_VALUE);
          newTouchpoint.intervalTime.interval = MIN_MINUTES_INTERVAL_VALUE;
        }
        break;
      case 'interval':
        setFieldValue('intervalTime.interval', validInterval(value, newTouchpoint.intervalTime.timeType));
        newTouchpoint.intervalTime.interval = validInterval(value, newTouchpoint.intervalTime.timeType);
        break;
      case 'type':
        setFieldValue('type', value);
        setFieldValue('message', TOUCHPOINT_DEFAULT_MESSAGE.sms);

        newTouchpoint.type = value;
        newTouchpoint.message = TOUCHPOINT_DEFAULT_MESSAGE.sms;
        // value === 'email'
        //   ? TOUCHPOINT_DEFAULT_MESSAGE.email
        //   : TOUCHPOINT_DEFAULT_MESSAGE.sms;
        break;
      default:
        setFieldValue(target, value);
        (newTouchpoint as any)[target] = value;
        break;
    }

    updateTouchpoint(cloneDeep(newTouchpoint));
  };

  const formattedInterval = `${interval} ${
    interval === 1 ? timeType.replace('s', '') : timeType
  } After ${previousTouchpointName}`;

  return (
    <>
      <IntervalBar visible={!!index} disabled={disabled} formik={formik} captureInput={captureInput} />
      <Row className="campaign-touchpoint flex border border-secondary rounded my-4">
        <Col className="campaign-touchpoint-name-input-container col-auto border-secondary d-flex">
          <div className="campaign-touchpoint-info text-muted d-flex flex-column justify-content-between">
            <FormGroup>
              <NameInput disabled={disabled} captureInput={captureInput} formik={formik} />
              <InputGroup>
                <Input
                  className="text-muted border border-muted pt-2"
                  type="select"
                  name="type"
                  disabled={disabled}
                  onChange={(e) => captureInput('type', e.target.value)}
                  onBlur={handleBlur}
                  value={type}
                  invalid={touched.type && !!errors.type}
                >
                  {TOUCHPOINT_OPTIONS.filter((o) => features
                    .includes(o.value))
                    .map(({ display, value }) => (
                      <option value={value}>{display}</option>
                    ))}
                </Input>
              </InputGroup>
            </FormGroup>
            <div className="mt-1">
              <p>
                You can use prospect tags like &#123;firstname&#125; to build
                a more personalized message.
              </p>
              <p data-testcafe={`touchpoint-scheduled-message-${index}`}>
                Scheduled for: &nbsp;
                {index === 0 && 'Immediately After Opt-in'}
                {index !== 0 && formattedInterval}
              </p>
            </div>
          </div>
          <OptionsDropdown md hidden={disabled} name={name} onDelete={deleteTouchpoint} />
        </Col>
        <Col className="campaign-touchpoint-template d-flex align-items-stretch p-0 d-flex flex-column">
          <>
            {
              type === TouchpointType.RVM
              && (
                <RVMInput
                  disabled={disabled}
                  rvmUrl={rvmUrl}
                  formik={formik}
                  captureInput={captureInput}
                />
              )
            }
            {
              (type === TouchpointType.Email || type === TouchpointType.FancyEmail)
              && (
                <EmailInput
                  disabled={disabled}
                  formik={formik}
                  onFocus={keywordsOnFocus}
                  captureInput={captureInput}
                />
              )
            }
            {type === TouchpointType.SMS && (
              <InputGroup>
                <Input
                  className="campaign-touchpoint-message"
                  type="textarea"
                  name="message"
                  disabled={disabled}
                  onChange={(e) => captureInput('message', e.target.value)}
                  onBlur={handleBlur}
                  onFocus={keywordsOnFocus}
                  value={message}
                  invalid={touched.message && !!errors.message}
                />
                <FormFeedback>{errors.message}</FormFeedback>
              </InputGroup>
            )}
            {displayKeywords ? (
              <div className="keywords">
                Tags: First Name - &#123;firstname&#125; , Last Name -
                &#123;lastname&#125;, Full Name - &#123;fullname&#125;
              </div>
            ) : null}
          </>
        </Col>
        <OptionsDropdown hidden={disabled} name={name} onDelete={deleteTouchpoint} />
      </Row>
    </>
  );
};
/* eslint-enable max-len */

export default TouchPointInput;
