import {
  action,
  Action,
  thunk,
  Thunk,
} from 'easy-peasy';
import { DEFAULT_API_CALL_STATE } from '../../constants';

import api from '../../api';
import { APICallState, Tenant } from '../../types';

export interface TenantModel {
  // State
  tenant: Tenant | null;
  tenantState: APICallState;
  tenantList: Tenant[];
  tenantListState: APICallState;
  // Setters
  setTenant: Action<TenantModel, Tenant>;
  setTenantState: Action<TenantModel, APICallState>;
  setTenantList: Action<TenantModel, Tenant[]>;
  setTenantListState: Action<TenantModel, APICallState>;
  // Methods
  getTenant: Thunk<TenantModel, string>;
  getTenants: Thunk<TenantModel>;
}

const tenant = (): TenantModel => ({
  tenant: null,
  tenantState: { ...DEFAULT_API_CALL_STATE },
  tenantList: [],
  tenantListState: { ...DEFAULT_API_CALL_STATE },
  setTenant: action((state, data) => {
    state.tenant = data;
  }),
  setTenantState: action((state, tenantState) => {
    state.tenantState = tenantState;
  }),
  setTenantList: action((state, tenantList) => {
    state.tenantList = tenantList;
  }),
  setTenantListState: action((state, tenantListState) => {
    state.tenantListState = tenantListState;
  }),
  getTenant: thunk(
    async ({ setTenant, setTenantState }, id) => {
      setTenantState({ ...DEFAULT_API_CALL_STATE, pending: true });

      try {
        const result = await api.tenants.findById(id);

        setTenant(result.data);
        setTenantState({ ...DEFAULT_API_CALL_STATE, success: true });
      } catch (ex) {
        setTenantState({ ...DEFAULT_API_CALL_STATE, failure: true });
      }
    },
  ),
  getTenants: thunk(
    async ({ setTenantList, setTenantListState }) => {
      setTenantListState({ ...DEFAULT_API_CALL_STATE, pending: true });

      try {
        const result = await api.tenants.find();
        setTenantList(result.data);
        setTenantListState({ ...DEFAULT_API_CALL_STATE, success: true });
      } catch (ex) {
        setTenantListState({ ...DEFAULT_API_CALL_STATE, failure: true });
      }
    },
  ),
});

export default tenant;
