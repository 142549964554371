import React from 'react';
import { Row } from 'reactstrap';

const ListHeader = (
  {
    link,
    display = true,
    background,
    children,
  }:
  {
    display?: boolean,
    background: {
      image: string,
      description: string,
    },
    link: any,
    children?: any,
  },
) => (
  <Row className="my-3" style={{ display: display ? 'block' : 'none' }}>
    <div>
      <img alt={background.description} src={background.image} />
      <div className="mt-3">
        {link}
      </div>
    </div>
    {children}
  </Row>
);

export default ListHeader;
