import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Form,
  FormGroup,
  Button,
  Col,
  Row,
  Input,
  Label,
  InputGroup,
  FormFeedback,
} from 'reactstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import noop from 'lodash.noop';

import TopLoader from '../components/TopLoader';

import { Team, UserRole } from '../../types';
import { TOP_LOAD_RATE, TOP_LOAD_START } from '../../constants';

import { useStoreActions, useStoreState } from '../store';

import '../../styles/user-form.scss';

const TeamSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

const CreateTeam = () => {
  const ref = useRef(null);
  const navigate = useNavigate();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = useFormik({
    validationSchema: TeamSchema,
    initialValues: {
      name: '',
    },
    onSubmit: noop,
  });

  const [data, setData] = useState({ name: '' } as Team);

  const profile = useStoreState((state) => state.profile.user);
  const isImpersonating = useStoreState((state) => state.impersonation.isImpersonating);

  const createState = useStoreState((state) => state.team.createState);

  const createTeam = useStoreActions((actions) => actions.team.createTeam);

  const isValid = !!data.name;

  const onSubmit = () => {
    createTeam({
      tenantId: profile?.tenantId!,
      name: data.name,
    });

    navigate('/team');
  };

  const onCancel = () => {
    setData({
      name: '',
    } as Team);

    navigate('/team');
  };

  const captureInput = (e: any) => {
    const {
      target: { name, value },
    } = e;

    setData({
      ...data,
      [name]: value,
    });
  };

  if (isImpersonating) navigate('/team');

  useEffect(() => {
    if (!!profile && profile.role !== UserRole.Owner) navigate('/team');
  }, [profile]);

  useEffect(() => {
    if (createState.pending) {
      (ref.current as any).continuousStart(
        TOP_LOAD_START,
        TOP_LOAD_RATE,
      );
    }

    if (createState.success || createState.failure) (ref.current as any).complete();
  }, [createState]);

  return (
    <Container className="d-flex justify-content-center">
      <TopLoader reference={ref} />
      <Col style={{ width: '90vw' }}>
        <Row className="justify-content-between pt-2">
          <div className="d-flex" />
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex">
              <Button
                color="primary"
                className="m-1"
                type="submit"
                disabled={!isValid || createState.pending}
                onClick={onSubmit}
              >
                Save Team
              </Button>
              <Button color="secondary" className="m-1" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </Row>
        <Row id="createUserFormContainer">
          <Form style={{ width: '100%' }}>
            <FormGroup row>
              <Col xs="9" md="9">
                <Label htmlFor="text-input">Name</Label>
                <InputGroup>
                  <Input
                    id="text-input"
                    type="text"
                    name="name"
                    placeholder="Enter Team Name"
                    onChange={(e) => {
                      handleChange(e);
                      captureInput(e);
                    }}
                    onBlur={handleBlur}
                    value={values.name}
                    invalid={touched.name && !!errors.name}
                  />
                  <FormFeedback>{errors.name}</FormFeedback>
                </InputGroup>
              </Col>
            </FormGroup>
          </Form>
        </Row>
      </Col>
    </Container>
  );
};

export default CreateTeam;
