import React, { useState } from 'react';
import {
  Button,
  FormFeedback,
  Input,
  InputGroup,
} from 'reactstrap';

import {
  faPencilAlt,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Touchpoint } from '../../../../types';

const NameInput = ({
  disabled = false,
  formik,
  captureInput,
}: {
  disabled?: boolean;
  formik: {
    values: Touchpoint,
    errors: any,
    touched: any,
    handleBlur: (e: React.FocusEvent<any, Element>) => void,
    handleChange: (e: React.ChangeEvent<any>) => void,
  },
  captureInput: (target: string, value: string) => void;
}) => {
  const {
    values: { name },
    errors,
    touched,
    handleBlur,
  } = formik;

  const [showNameText, setShowNameText] = useState(true);

  return (
    <>
      {showNameText ? (
        <h3 className="campaign-touchpoint-name">
          {name}
          {!disabled && (
            <Button
              color="link"
              onClick={() => setShowNameText(!showNameText)}
            >
              <FontAwesomeIcon icon={faPencilAlt as any} />
            </Button>
          )}
        </h3>
      ) : (
        <div className="d-flex mb-2">
          <InputGroup>
            <Input
              type="text"
              name="name"
              placeholder="Touchpoint Name"
              onChange={(e) => captureInput('name', e.target.value)}
              onBlur={handleBlur}
              value={name}
              invalid={touched.name && !!errors.name}
            />
            <FormFeedback>{errors.name}</FormFeedback>
          </InputGroup>
          <Button
            onClick={() => setShowNameText(!showNameText)}
            color="link"
            disabled={!!errors.name}
          >
            <FontAwesomeIcon icon={faCheck as any} />
          </Button>
        </div>
      )}
    </>
  );
};

export default NameInput;
