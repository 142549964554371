/* eslint-disable */
import React from 'react';
import {
  Navbar,
} from 'reactstrap';

import TabletNav from './TabletNav';
import DesktopNav from './DesktopNav';

import lock from '../../../utils/lock';
import { useStoreState, useStoreActions } from '../../store';

import '../../../styles/header.scss';

const Header = () => {
  const profile = useStoreState((state) => state.profile.user);
  const profileTenant = useStoreState((state) => state.profile.tenant);
  
  const {
    user: impersonatedUser,
    isImpersonating,
  } = useStoreState((state) => state.impersonation);
  
  const friendlyName = isImpersonating
  ? `${impersonatedUser?.firstName} ${impersonatedUser?.lastName}`
  : profile ? `${profile?.firstName} ${profile?.lastName}` : '';
  
  const endImpersonation = useStoreActions((actions) => actions.impersonation.resetImpersonation);
  // eslint-disable-next-line max-len
  const getPendingResponseCount = useStoreActions((actions) => actions.prospect.getPendingResponseCount);
  
  const handleSignOut = () => {
    endImpersonation();
    lock.logout({ returnTo: window.location.origin });
  }

  const stopImpersonation = async () => {
    await endImpersonation();
    getPendingResponseCount();
  }

  return (
    <>
      <Navbar expand="md" className="py-0">
        <DesktopNav
          tenant={profileTenant}
          friendlyName={friendlyName}
          isImpersonating={isImpersonating}
          signout={handleSignOut}
          endImpersonation={stopImpersonation}
        />
        <TabletNav
          tenant={profileTenant}
          friendlyName={friendlyName}
          isImpersonating={isImpersonating}
          signout={handleSignOut}
          endImpersonation={stopImpersonation}
        />
      </Navbar>
    </>
  );
};

export default Header;
