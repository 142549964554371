import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'reactstrap';

import { UserRole } from '../../types';
import { PAYMENTS_REDIRECT } from '../../constants';

import { isDefaultState } from '../../utils/apiCallState';

import { useStoreState } from '../store';

const PERCENT_LEFT_THRESHOLD = 25;
const DANGER_PERCENT_LEFT_THRESHOLD = 10;

// So this component is a little hacky,
// - but I didn't want to conflict with some of the stuff in layout
const Paywall = () => {
  const navigate = useNavigate();

  const profile = useStoreState((state) => state.profile.user);
  const subscription = useStoreState((state) => state.subscription.subscription);

  const profileState = useStoreState((state) => state.profile.userState);
  const subscriptionState = useStoreState((state) => state.subscription.subscriptionState);

  const isAdmin = profile?.role === UserRole.Admin;

  if (isDefaultState(profileState) || isDefaultState(subscriptionState) || isAdmin) return <></>;
  if (subscriptionState.success
    && !subscription
    && window.localStorage.pathname !== PAYMENTS_REDIRECT
  ) navigate(PAYMENTS_REDIRECT);

  /* eslint-disable no-unsafe-optional-chaining */
  // eslint-disable-next-line max-len
  const messagesLeft = (subscription?.limits.messages.limit!) - (subscription?.limits.messages.used!);
  const percentLeft = (messagesLeft / (subscription?.limits.messages.limit!)) * 100;
  /* eslint-enable no-unsafe-optional-chaining */

  const color = percentLeft < DANGER_PERCENT_LEFT_THRESHOLD ? 'danger' : 'warning';

  return percentLeft < PERCENT_LEFT_THRESHOLD ? (
    <Alert color={color}>
      {`You have ${
        messagesLeft >= 0 ? messagesLeft : 0
      } touchpoints remaining!`}
    </Alert>
  ) : (
    <></>
  );
};

export default Paywall;
