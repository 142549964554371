import {
  action, Action, thunk, Thunk,
} from 'easy-peasy';
import { StoreModel } from '.';

import { APICallState, ToastType, User } from '../../types';

import api from '../../api';
import { DEFAULT_API_CALL_STATE } from '../../constants';

export interface RegistrationModel {
  registerState: APICallState;
  setRegisterState: Action<RegistrationModel, APICallState>;
  register: Thunk<RegistrationModel, User, null, StoreModel>;
  reset: Action<RegistrationModel>;
}

const registration = (): RegistrationModel => ({
  registerState: {
    success: false,
    failure: false,
    pending: false,
  },
  setRegisterState: action((state, registerState) => { state.registerState = registerState; }),
  register: thunk(
    async ({ setRegisterState }, registrator, helpers) => {
      const { meta: { notify } } = helpers.getStoreActions();

      setRegisterState({ ...DEFAULT_API_CALL_STATE, pending: true });

      try {
        await api.users.register(registrator);

        notify({
          message: 'You have successfully registered',
          type: ToastType.success,
        });

        setRegisterState({ ...DEFAULT_API_CALL_STATE, success: true });
      } catch (ex) {
        setRegisterState({ ...DEFAULT_API_CALL_STATE, failure: true });

        notify({
          message: 'We failed to register your account',
          type: ToastType.error,
        });
      }
    },
  ),
  reset: action((state) => {
    state.registerState = { ...DEFAULT_API_CALL_STATE };
  }),
});

export default registration;
