import {
  action, Action, thunk, Thunk,
} from 'easy-peasy';
import { StoreModel } from '.';
import { DEFAULT_API_CALL_STATE } from '../../constants';

import api from '../../api';
import {
  APICallState,
  Product,
  Subscription,
  ToastType,
} from '../../types';

enum APICall {
  subscription,
  create,
  edit,
  cancel,
  productList,
}

export interface SubscriptionModel {
  // State
  subscription: Subscription | null,
  productList: Product[],
  productCount: number,
  subscriptionState: APICallState,
  createState: APICallState,
  editState: APICallState,
  cancelState: APICallState,
  productListState: APICallState,
  // Setters
  setSubscription: Action<SubscriptionModel, Subscription>;
  setList: Action<SubscriptionModel, { apiCall: APICall, list: any[], count: number }>;
  setAPICallState: Action<SubscriptionModel, { apiCall: APICall, value: APICallState }>;
  // Methods
  getBaseProducts: Thunk<SubscriptionModel>;
  getSubscription: Thunk<SubscriptionModel>;
  editSubscription: Thunk<SubscriptionModel, {
    priceId: string,
  }, null, StoreModel>;
  createSubscription: Thunk<SubscriptionModel, {
    paymentMethodId: string,
    priceId: string
  }, null, StoreModel>;
  cancelSubscription: Thunk<SubscriptionModel, string, null, StoreModel>;
}

const subscription = (): SubscriptionModel => ({
  subscription: null,
  productList: [],
  productCount: 0,
  subscriptionState: { ...DEFAULT_API_CALL_STATE },
  createState: { ...DEFAULT_API_CALL_STATE },
  editState: { ...DEFAULT_API_CALL_STATE },
  cancelState: { ...DEFAULT_API_CALL_STATE },
  productListState: { ...DEFAULT_API_CALL_STATE },
  setSubscription: action((state, data) => {
    state.subscription = data;
  }),
  setList: action((state, data) => {
    // eslint-disable-next-line default-case
    switch (data.apiCall) {
      case APICall.productList:
        state.productList = data.list;
        state.productCount = data.count;
        break;
    }
  }),
  setAPICallState: action((state, data) => {
    // eslint-disable-next-line default-case
    switch (data.apiCall) {
      case APICall.subscription:
        state.subscriptionState = data.value;
        break;
      case APICall.create:
        state.createState = data.value;
        break;
      case APICall.edit:
        state.editState = data.value;
        break;
      case APICall.cancel:
        state.cancelState = data.value;
        break;
      case APICall.productList:
        state.productListState = data.value;
        break;
    }
  }),
  getBaseProducts: thunk(
    async ({ setList, setAPICallState }) => {
      setAPICallState({
        apiCall: APICall.productList,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        const result = await api.subscription.product.findBase();

        setList({
          apiCall: APICall.productList,
          list: result,
          count: result.length,
        });

        setAPICallState({
          apiCall: APICall.productList,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.productList,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });
      }
    },
  ),
  getSubscription: thunk(
    async ({ setSubscription, setAPICallState }) => {
      setAPICallState({
        apiCall: APICall.subscription,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        const result = await api.subscription.getMySubscription();
        setSubscription(result);
        setAPICallState({
          apiCall: APICall.subscription,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.subscription,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });
      }
    },
  ),
  createSubscription: thunk(
    async ({ getSubscription, setAPICallState }, { paymentMethodId, priceId }, helpers) => {
      const { meta: { notify } } = helpers.getStoreActions();

      setAPICallState({
        apiCall: APICall.create,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        await api.subscription.create(paymentMethodId, priceId);

        setAPICallState({
          apiCall: APICall.create,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });

        notify({
          message: 'Created a new subscription',
          type: ToastType.success,
        });

        getSubscription();
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.create,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });

        notify({
          message: 'Failed to create a new subscription',
          type: ToastType.error,
        });
      }
    },
  ),
  editSubscription: thunk(
    async ({ getSubscription, setAPICallState }, { priceId }, helpers) => {
      const { meta: { notify } } = helpers.getStoreActions();

      setAPICallState({
        apiCall: APICall.edit,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        await api.subscription.upgrade(priceId);

        setAPICallState({
          apiCall: APICall.edit,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });

        notify({
          message: 'Updated the subscription',
          type: ToastType.success,
        });

        getSubscription();
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.edit,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });

        notify({
          message: 'Failed to update the subcription',
          type: ToastType.error,
        });
      }
    },
  ),
  cancelSubscription: thunk(
    async ({ getSubscription, setAPICallState }, _, helpers) => {
      const { meta: { notify } } = helpers.getStoreActions();

      setAPICallState({
        apiCall: APICall.cancel,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        await api.subscription.cancel();

        setAPICallState({
          apiCall: APICall.cancel,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });

        notify({
          message: 'Canceled the subscription',
          type: ToastType.success,
        });

        getSubscription();
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.cancel,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });

        notify({
          message: 'Failed to cancel the subscription',
          type: ToastType.error,
        });
      }
    },
  ),
});

export default subscription;
