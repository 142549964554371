import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Form,
  FormGroup,
  InputGroup,
  FormFeedback,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import noop from 'lodash.noop';

import { MessageType } from '../../../types';
import { MAX_SMS_MESSAGE_LENGTH } from '../../../constants';

import '../../../styles/prospect.scss';

const SMSMessageSchema = Yup.object().shape({
  message: Yup.string()
    .max(MAX_SMS_MESSAGE_LENGTH, `Can't exceed ${MAX_SMS_MESSAGE_LENGTH} characters`)
    .required('Required'),
});

const SendMessageModal = ({
  disabled,
  sendMessage,
}: { disabled: boolean, sendMessage: Function }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = useFormik({
    validationSchema: SMSMessageSchema,
    initialValues: {
      message: '',
    },
    onSubmit: noop,
  });

  const [isOpen, setIsOpen] = useState(false);

  const [message, setMessage] = useState('');

  const isSMSValid = SMSMessageSchema.isValidSync({ message });

  const internalToggle = () => setIsOpen(!isOpen);

  const captureInput = async (e: any) => {
    const { value } = e.target;

    setMessage(value);
  };

  const onSubmit = async () => {
    await sendMessage({
      body: message,
      type: MessageType.SMS,
    });

    internalToggle();
  };

  return (
    <>
      <Button
        disabled={disabled}
        onClick={internalToggle}
        className="m-2"
        color="primary"
        data-testcafe="send-sms-modal-button"
      >
        <FontAwesomeIcon className="mr-2" icon={faComment as any} />
        Text
      </Button>
      <Modal
        centered
        isOpen={isOpen}
        toggle={internalToggle}
        style={{ minWidth: '30vw' }}
      >
        <ModalHeader toggle={internalToggle} />
        <ModalBody style={{ minHeight: '20vh' }}>
          <Form>
            <FormGroup>
              <InputGroup>
                <Input
                  className={values.message.length > MAX_SMS_MESSAGE_LENGTH ? 'smsModalTextarea' : ''}
                  style={{ height: '25vh' }}
                  type="textarea"
                  name="message"
                  placeholder="Body"
                  onChange={(e) => {
                    handleChange(e);
                    captureInput(e);
                  }}
                  onBlur={handleBlur}
                  value={values.message}
                  invalid={touched.message && !!errors.message}
                />
                <FormFeedback>{errors.message}</FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup className="d-flex align-items-center justify-content-center modalSubmitButtonContainer">
              <Button
                color="primary"
                onClick={onSubmit}
                disabled={!isSMSValid || disabled}
                className="modalSubmitButton"
              >
                <FontAwesomeIcon className="mr-2" icon={faComment as any} />
                Send
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SendMessageModal;
