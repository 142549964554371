import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Spinner,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { Product } from '../../types';

import { useStoreActions, useStoreState } from '../store';

const CardForm = ({ product }: { product: Product | null }) => {
  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const profile = useStoreState((state) => state.profile.user);
  const subscription = useStoreState((state) => state.subscription.subscription);

  const editState = useStoreState((state) => state.subscription.editState);
  const createState = useStoreState((state) => state.subscription.createState);

  const submitState = subscription ? editState : createState;

  const editSubscription = useStoreActions((actions) => actions.subscription.editSubscription);
  const createSubscription = useStoreActions((actions) => actions.subscription.createSubscription);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (submitState.pending || !product) return;

    try {
      const { error, paymentMethod } = (await stripe?.createPaymentMethod({
        type: 'card',
        card: elements?.getElement(CardElement) as any,
      }) as any);

      if (subscription && subscription.active) {
        await editSubscription({ priceId: product.priceId });
      } else {
        await createSubscription({
          paymentMethodId: paymentMethod.id,
          priceId: product.priceId,
        });
      }
    } catch (ex) {
      console.log(ex);
    }

    navigate('/');
  };

  return (
    <form onSubmit={onSubmit}>
      <Card>
        <CardHeader>Payment details</CardHeader>
        <CardBody>
          <p>{`${profile?.firstName} ${profile?.lastName}`}</p>
          <CardElement />
        </CardBody>
        <CardFooter className="align-content-start">
          <Button type="submit" color="primary" disabled={submitState.pending || !product}>
            {submitState.pending ? <Spinner color="light" size="sm" /> : 'Subscribe'}
          </Button>
        </CardFooter>
      </Card>
      <small className="text-muted p-2">
        *By clicking subscribe above, you authorize that your credit card will
        be billed monthly
      </small>
    </form>
  );
};

export default CardForm;
