import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Form,
  FormGroup,
  Button,
  Col,
  Row,
  Input,
  Label,
  InputGroup,
  FormFeedback,
} from 'reactstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import noop from 'lodash.noop';

import TopLoader from '../components/TopLoader';

import { isDefaultState } from '../../utils/apiCallState';

import { Team, User, UserRole } from '../../types';
import { TOP_LOAD_RATE, TOP_LOAD_START } from '../../constants';

import { useStoreActions, useStoreState } from '../store';

import '../../styles/user-form.scss';

const UserSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required'),
  lastName: Yup.string()
    .required('Required'),
  email: Yup.string()
    .required('Required')
    .email('Invalid Email'),
  teamId: Yup.string()
    .required('Required'),
  role: Yup.string()
    .required('Required'),
});

const CreateUser = () => {
  const ref = useRef(null);
  const navigate = useNavigate();

  const profile = useStoreState((state) => state.profile.user);
  const isImpersonating = useStoreState((state) => state.impersonation.isImpersonating);

  const {
    hasTeam,
    details: team,
    teamList: teams,
    teamListState,
  } = useStoreState((state) => state.team);

  const createState = useStoreState((state) => state.user.createState);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = useFormik({
    validationSchema: UserSchema,
    initialValues: {
      teamId: team?._id ?? '',
      firstName: '',
      lastName: '',
      email: '',
      role: UserRole.TeamMember,
    },
    onSubmit: noop,
  });

  const [data, setData] = useState({
    teamId: team?._id ?? '',
    firstName: '',
    lastName: '',
    email: '',
    role: UserRole.TeamMember,
  } as User);

  const isUserValid = UserSchema.isValidSync(data);

  const createUser = useStoreActions((actions) => actions.user.createUser);

  const onSubmit = () => {
    if (!data.teamId) return;

    createUser({
      tenantId: profile?.tenantId!,
      teamId: data.teamId,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      role: data.role,
    });

    navigate('/team');
  };

  const onCancel = () => {
    setData({
      firstName: '',
      lastName: '',
      email: '',
    } as User);

    navigate('/team');
  };

  const captureInput = (e: any) => {
    const {
      target: { name, value },
    } = e;

    setData({
      ...data,
      [name]: value,
    });
  };

  if (isImpersonating) navigate('/team');

  useEffect(() => {
    if (!!profile
      && profile.role !== UserRole.Owner
      && profile.role !== UserRole.TeamLeader
    ) navigate('/team');

    // Need to create a team first
    if (!!profile
      && profile.role === UserRole.Owner
      && !isDefaultState(teamListState)
      && !teamListState.pending
      && !hasTeam
    ) navigate('/team');
  }, [profile, teams, teamListState, hasTeam]);

  useEffect(() => {
    if (createState.pending) {
      (ref.current as any).continuousStart(
        TOP_LOAD_START,
        TOP_LOAD_RATE,
      );
    }

    if (createState.success || createState.failure) (ref.current as any).complete();
  }, [createState]);

  return (
    <Container className="d-flex justify-content-center">
      <TopLoader reference={ref} />
      <Col style={{ width: '90vw' }}>
        <Row className="justify-content-between pt-2">
          <div className="d-flex" />
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex">
              <Button
                color="primary"
                className="m-1"
                type="submit"
                disabled={!isUserValid || createState.pending}
                onClick={onSubmit}
              >
                Save User
              </Button>
              <Button color="secondary" className="m-1" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </Row>
        <Row id="createUserFormContainer">
          <Form style={{ width: '100%' }}>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">First Name</Label>
              </Col>
              <Col xs="12" md="9">
                <InputGroup>
                  <Input
                    id="text-input"
                    type="text"
                    name="firstName"
                    placeholder="Enter First Name"
                    onChange={(e) => {
                      handleChange(e);
                      captureInput(e);
                    }}
                    onBlur={handleBlur}
                    value={values.firstName}
                    invalid={touched.firstName && !!errors.firstName}
                  />
                  <FormFeedback>{errors.firstName}</FormFeedback>
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="textarea-input">Last Name</Label>
              </Col>
              <Col xs="12" md="9">
                <InputGroup>
                  <Input
                    id="text-input"
                    type="text"
                    name="lastName"
                    placeholder="Enter Last Name"
                    onChange={(e) => {
                      handleChange(e);
                      captureInput(e);
                    }}
                    onBlur={handleBlur}
                    value={values.lastName}
                    invalid={touched.lastName && !!errors.lastName}
                  />
                  <FormFeedback>{errors.lastName}</FormFeedback>
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="email-input">Email</Label>
              </Col>
              <Col xs="12" md="9">
                <InputGroup>
                  <Input
                    id="email-input"
                    type="email"
                    name="email"
                    placeholder="Enter Email"
                    autoComplete="email"
                    onChange={(e) => {
                      handleChange(e);
                      captureInput(e);
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                    invalid={touched.email && !!errors.email}
                  />
                  <FormFeedback>{errors.email}</FormFeedback>
                </InputGroup>
              </Col>
            </FormGroup>
            {profile?.role === UserRole.Owner && (
              <>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="team-input">Team</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      id="team-input"
                      type="select"
                      name="teamId"
                      placeholder="Team"
                      onChange={(e) => {
                        handleChange(e);
                        captureInput(e);
                      }}
                      onBlur={handleBlur}
                      value={values.teamId}
                    >
                      {teams.map((t: Team) => (
                        <option key={t._id} value={t._id}>
                          {t.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="role-input">Role</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      id="role-input"
                      type="select"
                      name="role"
                      placeholder="Role"
                      onChange={(e) => {
                        handleChange(e);
                        captureInput(e);
                      }}
                      onBlur={handleBlur}
                      value={values.role}
                    >
                      <option key={UserRole.TeamLeader} value={UserRole.TeamLeader}>
                        {UserRole.TeamLeader}
                      </option>
                      <option key={UserRole.TeamMember} value={UserRole.TeamMember}>
                        {UserRole.TeamMember}
                      </option>
                    </Input>
                  </Col>
                </FormGroup>
              </>
            )}
          </Form>
        </Row>
      </Col>
    </Container>
  );
};

export default CreateUser;
