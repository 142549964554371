import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from 'reactstrap';

const ConfirmationModal = ({
  isOpen,
  title = 'Are you sure?',
  onCancel,
  onConfirm,
}: {
  isOpen: boolean,
  title: string,
  onCancel: Function,
  onConfirm: Function,
}) => (
  <Modal
    backdrop="static"
    isOpen={isOpen}
    style={{ minWidth: '30vw' }}
  >
    <ModalHeader>
      {title}
    </ModalHeader>
    <ModalBody
      style={{ display: 'flex', justifyContent: 'space-around' }}
    >
      <Button
        color="secondary"
        onClick={() => onCancel()}
        style={{ width: '30%' }}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        onClick={() => onConfirm()}
        style={{ width: '30%' }}
      >
        Confirm
      </Button>
    </ModalBody>
  </Modal>
);

export default ConfirmationModal;
