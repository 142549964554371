import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Form,
  FormGroup,
  InputGroup,
  FormFeedback,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import noop from 'lodash.noop';

import { MessageType } from '../../../types';

import '../../../styles/prospect.scss';

const EmailMessageSchema = Yup.object().shape({
  subject: Yup.string().required('Required'),
  message: Yup.string().required('Required'),
});

const SendEmailModal = ({
  disabled,
  sendMessage,
}: { disabled: boolean, sendMessage: Function }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = useFormik({
    validationSchema: EmailMessageSchema,
    initialValues: {
      subject: '',
      message: '',
    },
    onSubmit: noop,
  });

  const [isOpen, setIsOpen] = useState(false);

  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');

  const isEmailValid = EmailMessageSchema.isValidSync({ subject, message });

  const internalToggle = () => setIsOpen(!isOpen);

  const captureInput = async (e: any) => {
    const { value, name } = e.target;

    // eslint-disable-next-line default-case
    switch (name) {
      case 'subject':
        setSubject(value);
        break;
      case 'message':
        setMessage(value);
        break;
    }
  };

  const onSubmit = async () => {
    await sendMessage({
      body: message,
      subject,
      type: MessageType.Email,
    });

    internalToggle();
  };

  return (
    <>
      <Button
        disabled={disabled}
        onClick={internalToggle}
        className="m-2"
        color="primary"
        data-testcafe="send-email-modal-button"
      >
        <FontAwesomeIcon className="mr-2" icon={faEnvelope as any} />
        Email
      </Button>
      <Modal
        centered
        isOpen={isOpen}
        toggle={internalToggle}
        style={{ minWidth: '60vw' }}
      >
        <ModalHeader toggle={internalToggle} />
        <ModalBody style={{ minHeight: '60vh' }}>
          <Form>
            <FormGroup>
              <InputGroup>
                <Input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  onChange={(e) => {
                    handleChange(e);
                    captureInput(e);
                  }}
                  onBlur={handleBlur}
                  value={values.subject}
                  invalid={touched.subject && !!errors.subject}
                />
                <FormFeedback>{errors.subject}</FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <Input
                  style={{ height: '40vh' }}
                  type="textarea"
                  name="message"
                  placeholder="Body"
                  onChange={(e) => {
                    handleChange(e);
                    captureInput(e);
                  }}
                  onBlur={handleBlur}
                  value={values.message}
                  invalid={touched.message && !!errors.message}
                />
                <FormFeedback>{errors.message}</FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup className="d-flex align-items-center justify-content-center">
              <p className="text-secondary">
                Your email footer will be appended to this message
              </p>
            </FormGroup>
            <FormGroup className="d-flex align-items-center justify-content-center modalSubmitButtonContainer">
              <Button
                color="primary"
                onClick={onSubmit}
                disabled={!isEmailValid || disabled}
                className="modalSubmitButton"
              >
                <FontAwesomeIcon className="mr-2" icon={faEnvelope as any} />
                {' Send'}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SendEmailModal;
